import {CelOPEEnum} from "./CelOPEEnum";
import {BasePageI, BasePageO, PaginatedBaseCB} from "../PaginatedBaseCB";
import {BaseSpringServiceCB} from "@aiknow-srl/aik-ngx-http";


/**
 * I inner class
 */
class I {
  celId!: number;
}

/**
 * O inner class
 */

class O extends BasePageO {
  celRows!: CelRowDTO[];
  subcontractorCelRows!: CelRowDTO[];
}

class CelRowDTO {
  id!: number;
  category!: CelCategoryDTO;
  company!: CompanyDTO;
  amount!: number;
  isSubcontractor!: boolean;
  fosterCompany?: CompanyDTO;
}

class CelCategoryDTO {
  id!: number;
  code!: string;
  description!: string;
  addAlsoSubcontractorsAmount!: boolean;
}

class CompanyDTO {
  id!: number;
  taxCode!: string;
  companyName!: string;
  isMainCompany!: boolean;
}


/**
 * put reseller class
 */

export class GetCelDetailsCB extends BaseSpringServiceCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(CelOPEEnum.GetCelDetails);
    this.i = new I();
    this.o = new O();
  }

}
