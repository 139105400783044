import {BaseSpringServiceCB} from '@aiknow-srl/aik-ngx-http';
import {UserOPEEnum} from "./UserOPEEnum";

/**
 * I inner class
 */
class I {
  /**
   * Old password
   */
  currentPassword!: string;
  /**
   * new password
   */
  newPassword!: string;
}

/**
 * O inner class
 */

class O {

}


/**
 * put reseller class
 */

export class ChangePasswordCB extends BaseSpringServiceCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(UserOPEEnum.ChangePassword);
    this.i = new I();
    this.o = new O();
  }

}
