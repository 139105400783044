import {inject} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NGXLogger} from "ngx-logger";
import {EventBusService} from "@aiknow-srl/aik-ngx-eventbus";


export abstract class BaseService {

  protected logger: NGXLogger = inject(NGXLogger);
  protected translate = inject(TranslateService);
  protected eventBus: EventBusService = inject(EventBusService);
}
