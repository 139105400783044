import {CelOPEEnum} from "./CelOPEEnum";
import {BaseSpringServiceCB} from "@aiknow-srl/aik-ngx-http";


/**
 * I inner class
 */
class I {
  id?: number;
  celId!: number;
  categoryId!: number;
  companyId!: number;
  amount!: number;
  isSubcontractor!: boolean;
  fosterCompanyId?: number;

}

/**
 * O inner class
 */

class O {
  id!: number;
}

/**
 * put reseller class
 */

export class PutCelRowCB extends BaseSpringServiceCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(CelOPEEnum.PutCelRow);
    this.i = new I();
    this.o = new O();
  }

}
