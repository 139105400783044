export class Paths {
  public static readonly LOGIN = '';
  public static readonly HOME = 'home';
  public static readonly EMPTY = 'empty';

  // CEL GROUP
  public static readonly CEL_DASHBOARD = 'celDashboard';
  public static readonly CEL_REGISTRY = 'celRegistry';
  public static readonly CEL_CATEGORY_REGISTRY = 'celCategoryRegistry';
  public static readonly COMPANY_REGISTRY = 'companyRegistry';

  // ADMIN GROUP
  public static readonly USER_MANAGEMENT = 'userManagement';

}
