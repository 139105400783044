import {Component, inject, OnInit} from "@angular/core";
import {LocationStrategy} from "@angular/common";
import {AppService} from "../Services/App.service";
import {GlobalModelService} from "../Services/GlobalModel.service";
import {NGXLogger} from "ngx-logger";
import {TranslateService} from "@ngx-translate/core";
import {EventBusService} from "@aiknow-srl/aik-ngx-eventbus";

@Component({
  selector: '',
  template: 'NO-UI'
})
export abstract class BaseComponent implements OnInit {

  protected location = inject(LocationStrategy);
  protected translate = inject(TranslateService);
  protected eventBus: EventBusService = inject(EventBusService);
  protected logger: NGXLogger = inject(NGXLogger);
  gmService: GlobalModelService = inject(GlobalModelService);
  appService: AppService = inject(AppService);

  isMobile?: boolean;
  isSmallScreen?: boolean;
  isMediumScreen?: boolean;

  protected readonly PAGESIZE_OPTIONS = [10, 25, 50, 100];
  protected totalPages?: number;
  protected totalElements?: number;
  protected page = 0;
  protected pageSize = this.PAGESIZE_OPTIONS[0];

  protected primaryButtonAttributes = {
    class: 'primaryButton'
  }

  ngOnInit(): void {

    // Set mobile flag
    this.isMobile = this.appService.isMobileScreen();
    this.isSmallScreen = this.appService.isSmallScreen();
    this.isMediumScreen = this.appService.isMediumScreen();
    window.onresize = () => {
      this.isMediumScreen = this.appService.isMediumScreen();
      this.isSmallScreen = this.appService.isSmallScreen();
      this.isMobile = this.appService.isMobileScreen();
    };
  }
}
