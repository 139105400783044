<div class="celDashboardContainer">

  <div class="celDashboardFilter">


    <!--  ************************************  -->
    <!--            START-DATE FILTER           -->
    <!--  ************************************  -->
    <mat-form-field class="aFifthOfWidthFlex" appearance="outline">
      <mat-label>{{'CEL_DASHBOARD.FILTER_BY_STARTDATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="startDateS" (dateChange)="assignStartDate($event)" [value]="startDate">
      <mat-datepicker-toggle matSuffix [for]="startDateS"></mat-datepicker-toggle>
      <mat-datepicker #startDateS></mat-datepicker>
    </mat-form-field>

    <!--  ************************************  -->
    <!--              END-DATE FILTER           -->
    <!--  ************************************  -->
    <mat-form-field class="aFifthOfWidthFlex" appearance="outline">
      <mat-label>{{'CEL_DASHBOARD.FILTER_BY_ENDDATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDateS" (dateChange)="assignEndDate($event)" [value]="endDate">
      <mat-datepicker-toggle matSuffix [for]="endDateS"></mat-datepicker-toggle>
      <mat-datepicker #endDateS></mat-datepicker>
    </mat-form-field>

    <!-- ******************************* -->
    <!--         REMEMBER ME FLAG        -->
    <!-- ******************************* -->
    <mat-slide-toggle color="primary"
                      [labelPosition]="LABEL_POSITION"
                      [(ngModel)]="soaCalc"
                      (ngModelChange)="getCelStatistics()"
                      name="soaCalcToggle"
                      class="toggleMargins">
      {{'CEL_DASHBOARD.SOA_CALC' | translate }}
    </mat-slide-toggle>

    <div style="flex: 1 1"></div>

    <div>
      <button mat-raised-button color="primary" class="findButton"
              (click)="getCelStatistics()">{{'BUTTONS.SHOW' | translate }}</button>
    </div>

  </div>

  <div class="celDashboardChart">
    <div id="statisticsChart" style="width: 100%; height: 100%"></div>
  </div>


</div>


