import {Component, Inject, inject, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../BaseComponent";
import {Cel} from "../../../models/cel/Cel";
import {GetCelListCB} from "../../../ope/cel/GetCelListCB";
import {CelOPEService} from "../../../Services/ope/CelOPE.service";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {PageEvent} from "@angular/material/paginator";
import {GetCelDetailsCB} from "../../../ope/cel/GetCelDetailsCB";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {CelDetails} from "../../../models/cel/CelDetails";
import {CelRow} from "../../../models/cel/CelRow";
import {NgForm} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatDatepicker, MatDatepickerInputEvent} from "@angular/material/datepicker";
import {PutCelCB} from "../../../ope/cel/PutCelCB";
import {PdfViewerDialogComponent} from "../../common/pdf-viewer-dialog/pdf-viewer-dialog.component";
import {GetCelCategoryListCB} from "../../../ope/cel/GetCelCategoryListCB";
import {CelCategory} from "../../../models/cel/CelCategory";
import {GetAllCompanyListCB} from "../../../ope/cel/GetAllCompanyListCB";
import {CelCompany} from "../../../models/cel/CelCompany";
import {filter} from "rxjs";
import {MatAccordion} from "@angular/material/expansion";
import {PutCompanyCB} from "../../../ope/cel/PutCompanyCB";
import {PutCelRowCB} from "../../../ope/cel/PutCelRowCB";
import {DelCelRowCB} from "../../../ope/cel/DelCelRowCB";
import {DelCelCB} from "../../../ope/cel/DelCelCB";

@Component({
  selector: 'app-cel-registry',
  templateUrl: './cel-registry.component.html',
  styleUrls: ['./cel-registry.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CelRegistryComponent extends BaseComponent implements OnInit {

  celOPEService = inject(CelOPEService);

  filteredCelNum?: string;
  filteredProcuringStation?: string;
  filteredSubject?: string;
  selectedYear?: number;
  selectedCategories?: number[];

  cels: Cel[] = new Array<Cel>();
  selectedRow?: Cel;
  displayedColumns: string[] = ['celNum', 'celDate', 'procuringStation', 'subject', 'cig','startWorkDate', 'endWorkDate', 'accountedAmount', 'actions'];
  dataSource!: MatTableDataSource<Cel>;

  celDetails?: CelDetails;
  detailIsLoading = false;
  detailTabIndex = 0;

  fostersCelRowColumns: string[] = ['rowCategory', 'company', 'rowAmount'];
  fostersCelRows!: MatTableDataSource<CelRow>;
  subContractorsCelRowColumns: string[] = ['rowCategory', 'company', 'fosterCompany', 'rowAmount'];
  subContractorsCelRows!: MatTableDataSource<CelRow>;

  public dialog = inject(MatDialog);

  override ngOnInit() {
    super.ngOnInit();

    this.loadData();
  }

  loadData() {
    this.getCelsList();
  }

  canRead() {
    return true;
  }

  getCelsList(refreshSelectedRow = false) {
    const cb = new GetCelListCB();
    cb.i.page = this.page;
    cb.i.pageSize = this.pageSize;

    if (!!this.selectedYear && this.selectedYear > 0) {
      cb.i.celYear = this.selectedYear;
    }

    if (!!this.filteredCelNum && this.filteredCelNum.length > 0) {
      cb.i.celNum = this.filteredCelNum;
    }

    if (!!this.filteredProcuringStation && this.filteredProcuringStation.length > 0) {
      cb.i.procuringStation = this.filteredProcuringStation;
    }

    if (!!this.filteredSubject && this.filteredSubject.length > 0) {
      cb.i.subject = this.filteredSubject;
    }

    if (!!this.selectedCategories && this.selectedCategories.length > 0) {
      cb.i.categories = this.selectedCategories;
    }

    this.celOPEService.getCelList(cb).then((_) => {
      this.cels = cb.o.cels;
      this.totalPages = cb.o.totalPages;
      this.totalElements = cb.o.totalElements;
      this.dataSource = new MatTableDataSource<Cel>(this.cels);

      if (refreshSelectedRow && !!this.selectedRow) {
        this.selectedRow = this.cels.find(elem => elem.id === this.selectedRow?.id)
      }
    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });
  }


  printDate(date: any): string {
    if (!!date) {
      const theDate = new Date(date);
      return this.appService.printDateTimeInItalianFormat(theDate, false);
    }
    return '-';
  }

  getCelDetails(row: Cel) {

    this.detailIsLoading = true;
    if (this.selectedRow?.id === row.id) {
      this.selectedRow = undefined;
    } else {
      this.selectedRow = row;
    }

    if (!!this.selectedRow) {
      const cb = new GetCelDetailsCB();
      cb.i.celId = row.id;

      this.celOPEService.getCelDetails(cb).then((_) => {

        this.celDetails = new CelDetails();
        this.celDetails.celRows = cb.o.celRows;
        this.celDetails.subcontractorCelRows = cb.o.subcontractorCelRows;
        this.fostersCelRows = new MatTableDataSource<CelRow>(this.celDetails.celRows);
        this.subContractorsCelRows = new MatTableDataSource<CelRow>(this.celDetails.subcontractorCelRows);
        this.detailIsLoading = false;

      }).catch((error) => {
        this.appService.openErrorSnackbar(error);
        this.detailIsLoading = false;
      });
    }

  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getCelsList();
    return event;
  }

  canEdit() {
    return true;
  }

  editCel(element: Cel) {
    const dialogRef = this.dialog.open(UpsertCelDialogComponent, {
      width: '60vw',
      data: {
        element: JSON.parse(JSON.stringify(element)),
        isUpdate: true
      }
    });

    dialogRef.afterClosed().subscribe(
      async (result) => {
        await this.getCelsList(true);
      }, error => {
        console.log(error);
      }
    );
  }

  canDelete() {
    return true;
  }

  deleteCel(element: Cel) {

    const cb = new DelCelCB();
    cb.i.id = element.id;

    this.celOPEService.delCel(cb).then((_) => {
      this.appService.openTranslatedSuccessSnackbar('CEL.DELETE_SUCCESS');
      this.getCelsList();

    }, (error) => {
      this.appService.openErrorSnackbar(error);
    });

  }

  canEditCels() {
    return true;
  }

  addCel() {
    const dialogRef = this.dialog.open(UpsertCelDialogComponent, {
      width: '60vw',
      data: {
        element: new Cel(),
        isUpdate: false
      }
    });

    dialogRef.afterClosed().subscribe(
      () => {
        this.getCelsList(true);
      }, error => {
        console.log(error);
      }
    );
  }

  downloadCel(element: Cel) {
    const url = this.appService.composeGetFileUrl(element.celFile);

    this.dialog.open(PdfViewerDialogComponent, {
      width: '70vw',
      data: url
    });

  }
}



/*--------------------------------
   UPDATE FLAWS DIALOG Component
 -------------------------------*/

@Component({
  templateUrl: 'upsert-cel-dialog.html',
  selector: 'app-upsert-cel-dialog',
  styleUrls: ['./cel-registry.component.css']
})
export class UpsertCelDialogComponent extends BaseComponent implements OnInit {

  username?: string;
  isSavingData = false;
  element: Cel;
  isUpdate = false;

  public celDate?: Date;
  public celDateIsOk = false;
  public celStartDate?: Date;
  public celStartDateIsOk = false;
  public celEndDate?: Date;
  public celEndDateIsOk = false;

  public readonly CEL_TAB = 0;
  public readonly CEL_ROWS_TAB = 1;

  public selTab = 0;

  @ViewChild('upsertCel') upsertCel!: NgForm;
  @ViewChild(MatTable) table!: MatTable<CelRow>;

  private dialog = inject(MatDialogRef<UpsertCelDialogComponent>);
  private celOpeService: CelOPEService = inject(CelOPEService);

  public celFile?: File;

  celCategories: CelCategory[] = new Array<CelCategory>();
  companies: CelCompany[] = new Array<CelCompany>();
  filteredCompanies: CelCompany[] = new Array<CelCompany>();

  public newCelRow = new CelRow();
  public newCompany = new CelCompany();

  celRows: CelRow[] = new Array<CelRow>();
  celRowsDataSource!: MatTableDataSource<CelRow>;

  detailIsLoading = false;

  celRowColumns: string[] = ['rowCategory', 'company', 'fosterCompany', 'rowAmount', 'delete'];

  addCompanyState = false;
  @ViewChild(MatAccordion) accordion?: MatAccordion;

  /**
   * Material Toggle Position
   */
  readonly LABEL_POSITION = 'after';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any)
  {
    super();
    this.dialog.disableClose = true;
    this.element = data.element;
    this.isUpdate = data.isUpdate;

    if (this.isUpdate) {

      this.getCelDetails(this.element);

      this.celDate = new Date(this.element.celDate);
      this.celStartDate = new Date(this.element.startWorkDate);
      this.celEndDate = new Date(this.element.endWorkDate);
      this.celDateIsOk = true;
      this.celStartDateIsOk = true;
      this.celEndDateIsOk = true;


    } else {
      this.celRowsDataSource = new MatTableDataSource<CelRow>(this.celRows);
    }

  }

  override ngOnInit() {
    super.ngOnInit();

    this.loadCompanies();
    this.loadCategories();
  }

  getCelDetails(row: Cel) {

    this.detailIsLoading = true;
    const cb = new GetCelDetailsCB();
    cb.i.celId = row.id;

    this.celOpeService.getCelDetails(cb).then((_) => {

      this.celRows = new Array<CelRow>();
      this.celRows.push(...cb.o.celRows);
      this.celRows.push(...cb.o.subcontractorCelRows);

      this.celRowsDataSource = new MatTableDataSource<CelRow>(this.celRows);

      this.detailIsLoading = false;

    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
      this.detailIsLoading = false;
    });

  }

  loadCategories() {
    const cb = new GetCelCategoryListCB();

    this.celOpeService.getCelCategoryList(cb).then((_) => {
      this.celCategories = cb.o.categories;
    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });
  }

  loadCompanies() {
    // TODO

    const cb = new GetAllCompanyListCB();

    this.celOpeService.getAllCompanyList(cb).then((_) => {
      this.companies = cb.o.companies;
      this.filteredCompanies = cb.o.companies;
    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });
  }

  assignCelDate(date: MatDatepickerInputEvent<Date, any | null>): void {
    if (!!this.element && !!date.value) {
      this.celDate = date.value;
      this.celDateIsOk = true;
    } else {
      this.celDateIsOk = false;
      this.celDate = undefined;
    }

  }

  assignCelStartDate(date: MatDatepickerInputEvent<Date, any | null>): void {
    if (!!this.element && !!date.value) {
      this.celStartDate = date.value;
      this.celStartDateIsOk = true;
    } else {
      this.celStartDate = undefined;
      this.celStartDateIsOk = false;
    }

  }

  assignCelEndDate(date: MatDatepickerInputEvent<Date, any | null>): void {
    if (!!this.element && !!date.value) {
      this.celEndDate = date.value;
      this.celEndDateIsOk = true;
    } else {
      this.celEndDate = undefined;
      this.celEndDateIsOk = false;
    }

  }


  saveCel() {

    const thereIsNewFile = !!this.celFile;

    if (!this.isUpdate && !thereIsNewFile) {
      this.appService.openTranslatedErrorSnackbar('UPSERT_CEL.MISSING_CEL_INFO');
      return;
    }

    if (!!this.celFile) {
      this.celOpeService.putCelFile(this.celFile).then((result) => this.saveCelData(result.o.filePath)).catch((error) => {
        this.appService.openErrorSnackbar(error);
      })
    } else {
      this.saveCelData(null);
    }



  }

  private saveCelData(filePath: any) {
    const cb = new PutCelCB();

    if (!!this.celDate && !!this.celStartDate && !!this.celEndDate) {
      if (!!this.element.id) {
        cb.i.id = this.element.id;
      }

      cb.i.celDate = this.celDate.getTime();
      cb.i.startWorkDate = this.celStartDate.getTime();
      cb.i.endWorkDate = this.celEndDate.getTime();

      cb.i.celNum = this.element.celNum;
      cb.i.cig = this.element.cig;
      cb.i.subject = this.element.subject;
      cb.i.procuringStation = this.element.procuringStation;
      cb.i.accountedAmount = this.element.accountedAmount;

      if (!!filePath) {
        cb.i.fileName = filePath;
      } else {
        cb.i.fileName = this.element.celFile;
      }



      this.celOpeService.putCel(cb).then((_) => {
        this.appService.openTranslatedSuccessSnackbar((!!this.element.id) ? 'UPSERT_CEL.UPDATE_SUCCESS' : 'UPSERT_CEL.INSERT_SUCCESS')
        //this.dialog.close(true);

        this.element.id = cb.o.id;

        // Go to CEL ROWS FORM
        this.selTab = this.CEL_ROWS_TAB;

      }).catch((error2) => {
        this.appService.openErrorSnackbar(error2);
      })

    } else {
      this.appService.openTranslatedErrorSnackbar('UPSERT_CEL.MISSING_CEL_INFO');
    }
  }



  celFileOk(target: EventTarget | null) {

    if (!!target) {
      // @ts-ignore
      this.celFile = (target as HTMLInputElement).files[0];

    }

  }

  printCelFileName() {
    if (!!this.celFile) {
      return this.celFile.name;
    } else if (!!this.element.celFile) {
      return this.element.celFile;
    }

    return null;
  }

  fileIsPresent() {
    return !!this.element.celFile || !!this.celFile;
  }

  filterCompanies($event: any) {
    const filter = $event.target.value;

    this.filteredCompanies = this.companies.filter(e => e.companyName.toUpperCase().includes(filter.toUpperCase()) || e.taxCode.toUpperCase().includes(filter.toUpperCase()));
  }


  setCompany(company: CelCompany) {

    if (!!company) {

      if (this.newCelRow.company?.taxCode !== company.taxCode) {
        this.newCelRow.company = undefined;
      }

      this.newCelRow.fosterCompany = undefined;

      this.newCelRow.company = company;
      this.accordion?.closeAll();



    }

  }


  printCompanyName() {

    if (!this.newCelRow.company) {
      return '';
    }
    return this.newCelRow?.company?.companyName;
  }

  protected readonly CelCompany = CelCompany;

  createNewCompany() {
    this.newCompany = new CelCompany();
  }

  addCompany() {

    const cb = new PutCompanyCB();

    cb.i.companyName = this.newCompany.companyName;
    cb.i.taxCode = this.newCompany.taxCode;

    this.celOpeService.putCompany(cb).then((_) => {
      this.appService.openTranslatedSuccessSnackbar('UPSERT_CEL.COMPANY_INSERT_SUCCESS');

      const cbinn = new GetAllCompanyListCB();

      this.celOpeService.getAllCompanyList(cbinn).then((_) => {
        this.companies = cbinn.o.companies;
        this.filteredCompanies = cbinn.o.companies;
        this.newCelRow.company = this.companies.find(e => e.id === cb.o.id);
        this.addCompanyState = false;
        this.accordion?.closeAll();
      }).catch((error) => {
        this.appService.openErrorSnackbar(error);
      });

    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });

  }

  addCelRow() {

    if (!this.newCelRow) {
      return;
    }



    if (this.newCelRow.isSubcontractor) {
      this.newCelRow.fosterCompany = this.companies.find(a => a.isMainCompany);
    }

    console.log(this.newCelRow);

    if (!this.checkCelRowIsOk(this.newCelRow)) {
      this.appService.openTranslatedErrorSnackbar('UPSERT_CEL.MISSING_ROW_INFO');
      return;
    }

    // Check if there is another row with same company taxCode and category id
    const found = this.celRows.find(e => e.company?.taxCode === this.newCelRow.company?.taxCode && e.category?.id === this.newCelRow.category?.id);

    if (!!found) {
      this.appService.openTranslatedErrorSnackbar('UPSERT_CEL.DUPLICATE_ROW');
      return;
    }



    this.putCelRow(this.newCelRow);


  }

  putCelRow(celRow: CelRow) {

    const cb = new PutCelRowCB();

    if (!!celRow.category && !!celRow.company && !!celRow.amount) {
      cb.i.celId = this.element.id;
      cb.i.categoryId = celRow.category.id;
      cb.i.companyId = celRow.company.id;
      cb.i.amount = celRow.amount;
      cb.i.isSubcontractor = celRow.isSubcontractor;
      cb.i.fosterCompanyId = celRow.fosterCompany?.id;

      this.celOpeService.putCelRow(cb).then((_) => {
        // set id of the row in the celRows array

        celRow.id = cb.o.id;

        this.celRows.push(JSON.parse(JSON.stringify(celRow)));
        this.appService.openTranslatedSuccessSnackbar('UPSERT_CEL.ADDED_OK');


        this.table.renderRows();

        this.newCelRow.fosterCompany = undefined;
        this.newCelRow = new CelRow();

      }).catch((error) => {
        this.appService.openErrorSnackbar(error);
      } );

    } else {
      this.appService.openTranslatedErrorSnackbar('UPSERT_CEL.MISSING_ROW_INFO');
    }

  }

  removeRow(element: CelRow) {
    const index = this.celRows.indexOf(element);

    if (index > -1) {
      this.celRows.splice(index, 1);
      this.table.renderRows();

      const cb = new DelCelRowCB();
      cb.i.id = element.id;

      this.celOpeService.delCelRow(cb).then((_) => {
        this.appService.openTranslatedSuccessSnackbar('UPSERT_CEL.REMOVED_OK');
      }, (error)=> {
        this.appService.openErrorSnackbar(error);

      });

    }

  }

  toggleSubcontractor(event: any) {
    if (!event) {
      // Not subcontractor
      this.newCelRow.fosterCompany = undefined;
    }

  }

  checkCelRowIsOk(newCelRow: CelRow): boolean {

    // Check whether category, company and amount are set
    if (!newCelRow.category || !newCelRow.company || !newCelRow.amount) {
      return false;
    }
    return true;

  }
}
