export enum CelOPEEnum {
  GetCelList = 'getCelList',
  GetCelDetails = 'getCelDetails',
  GetCompanyList = 'getCompanyList',
  GetAllCompanyList = 'getAllCompanyList',
  PutCompany = 'putCompany',
  DelCompany = 'delCompany',
  GetCelCategoryList = 'getCelCategoryList',
  PutCelCategory = 'putCelCategory',
  DelCelCategory = 'delCelCategory',
  PutCel = 'putCel',
  PutCelFile = 'putCelFile',
  PutCelRow = 'putCelRow',
  DelCel = 'delCel',
  DelCelRow = 'delCelRow',
  GetCategoryDetails = 'getCategoryDetails',
  GetStatistics = 'getStatistics'
}
