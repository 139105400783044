import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import { LoginComponent } from './Components/login/login.component';
import { HomeComponent } from './Components/home/home.component';
import Bugsnag from "@bugsnag/js";
import {environment} from "../environments/environment";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData} from "@angular/common";
import localeIt from '@angular/common/locales/it';  // to register italian
import {BugsnagErrorHandler} from "@bugsnag/plugin-angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {MatChipsModule} from "@angular/material/chips";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSliderModule} from "@angular/material/slider";
import {MatCardModule} from "@angular/material/card";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSortModule} from "@angular/material/sort";
import {MatSelectModule} from "@angular/material/select";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatMenuModule} from "@angular/material/menu";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDividerModule} from "@angular/material/divider";
import {MatTabsModule} from "@angular/material/tabs";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {MatExpansionModule} from "@angular/material/expansion";
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from "@angular/material/radio";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatBadgeModule} from "@angular/material/badge";
import {getItalianPaginatorIntl} from "./utils/PaginatorFormatter";
import {SpringRestModule, SpringRestService} from "@aiknow-srl/aik-ngx-http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ChangePasswordDialogComponent, TopbarComponent} from "./Components/topbar/topbar.component";
import {OrderByPipe} from "./Pipes/orderBy.pipe";
import {SafePipe} from "./Pipes/safe.pipe";
import {EmptyComponent} from "./Components/empty/empty.component";
import {CelRegistryComponent, UpsertCelDialogComponent} from './Components/cel/cel-registry/cel-registry.component';
import { CelCategoryRegistryComponent } from './Components/cel/cel-category-registry/cel-category-registry.component';
import {
  CelCompanyRegistryComponent,
  UpdateCompanyDialogComponent
} from './Components/cel/cel-company-registry/cel-company-registry.component';
import BugsnagPerformance from "@bugsnag/browser-performance";
import { PdfViewerDialogComponent } from './Components/common/pdf-viewer-dialog/pdf-viewer-dialog.component';
import {APP_DATE_FORMATS, AppDateAdapter} from "./utils/NgbDateCustomParserFormatter";
import {DecimalNumberFormatterDirective} from "./directives/DecimalNumberFormatter.directive";
import {
  CelCategoryDetailsDialogComponent,
  CelDashboardComponent
} from './Components/cel/dashboard/cel-dashboard.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/languages/', '.json');
}

registerLocaleData(localeIt);

if (environment.production) {
  Bugsnag.start({apiKey: '63e722947a62335008c2da822c6bf9b6'});
  BugsnagPerformance.start({ apiKey: '63e722947a62335008c2da822c6bf9b6' })
}

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  if (environment.production) {
    return new BugsnagErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  declarations: [
    SafePipe,
    OrderByPipe,
    AppComponent,
    LoginComponent,
    HomeComponent,
    EmptyComponent,
    TopbarComponent,
    ChangePasswordDialogComponent,
    CelRegistryComponent,
    CelCategoryRegistryComponent,
    CelCompanyRegistryComponent,
    UpdateCompanyDialogComponent,
    UpsertCelDialogComponent,
    PdfViewerDialogComponent,
    DecimalNumberFormatterDirective,
    CelDashboardComponent,
    CelCategoryDetailsDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SpringRestModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      enableSourceMaps: true,
      colorScheme: ['black', 'black', 'teal', 'gray', 'orange', 'red', 'purple'],
      timestampFormat: 'dd/MM/yyyy HH:mm:ss.SSS (z)',
      disableFileDetails: false,
    }),
    MatSnackBarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSliderModule,
    MatCardModule,
    MatDialogModule,
    MatSortModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatTabsModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatRadioModule,
    MatGridListModule,
    MatBadgeModule,
    MatChipsModule,
  ],
  providers: [{provide: MatPaginatorIntl, useValue: getItalianPaginatorIntl()}, {
    provide: ErrorHandler,
    useFactory: errorHandlerFactory
  },{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: {color: 'primary'},
  },
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    DatePipe, DecimalPipe, SafePipe, OrderByPipe, CurrencyPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
