<h2 mat-dialog-title>
  <span>{{'CHANGE_PASSWORD.COMPNAME' | translate }}</span>
</h2>
<mat-dialog-content class="mat-typography">
  <form #changePasswordForm="ngForm">

    <!--  ************************************  -->
    <!--                USERNAME                -->
    <!--  ************************************  -->
    <mat-form-field class="fullWidthField mat-form-field-appearance-outline">
      <mat-label>{{'CHANGE_PASSWORD.EMAIL' | translate }}</mat-label>
      <input matInput #userName="ngModel" required name="usernameChangePassword" [(ngModel)]="username">
      <mat-error *ngIf="userName.invalid">
        {{'CHANGE_PASSWORD.EMAIL_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <!-- ******************************* -->
    <!--     OLD PASSWORD INPUT FIELD    -->
    <!-- ******************************* -->
    <mat-form-field class="fullWidthField mat-form-field-appearance-outline">
      <mat-label>{{'CHANGE_PASSWORD.OLD_PASSWORD' | translate }}</mat-label>
      <input type="password" matInput required #oldPswdInput="ngModel" name="oldPasswordChangePassword" [(ngModel)]="oldPassword">
      <mat-error *ngIf="oldPswdInput.invalid">
        {{'CHANGE_PASSWORD.OLD_PASSWORD_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <!-- ******************************* -->
    <!--     NEW PASSWORD INPUT FIELD    -->
    <!-- ******************************* -->
    <mat-form-field class="fullWidthField mat-form-field-appearance-outline">
      <mat-label>{{'CHANGE_PASSWORD.NEW_PASSWORD' | translate }}</mat-label>
      <input type="password" matInput required #newPswdInput="ngModel" name="newPassword" [(ngModel)]="newPassword">
      <mat-error *ngIf="newPswdInput.invalid">
        {{'CHANGE_PASSWORD.NEW_PASSWORD_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <!-- ******************************* -->
    <!--    CNF PASSWORD INPUT FIELD     -->
    <!-- ******************************* -->
    <mat-form-field class="fullWidthField mat-form-field-appearance-outline">
      <mat-label>{{'CHANGE_PASSWORD.CONFIRM_PASSWORD' | translate }}</mat-label>
      <input type="password" matInput required #cnfPswdInput="ngModel" name="cnfPassword"
             [(ngModel)]="confirmPassword">
      <mat-error *ngIf="cnfPswdInput.invalid">
        {{'CHANGE_PASSWORD.CONFIRM_PASSWORD_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

  </form>
</mat-dialog-content>

<!--  *****************************************  -->
<!--    CLOSE/SAVE DIALOG ACTION BUTTON          -->
<!--  *****************************************  -->
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">{{'CHANGE_PASSWORD.CLOSE' | translate }}</button>
  <button mat-raised-button color="primary" class="primaryButton" [disabled]="changePasswordForm.invalid" (click)="changePassword()">{{'CHANGE_PASSWORD.RESET' | translate }}</button>
</mat-dialog-actions>
