import {Component, inject, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PlatformLocation} from '@angular/common';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {MatSidenav} from '@angular/material/sidenav';
import {BaseComponent} from "../BaseComponent";
import {SpringRestService} from "@aiknow-srl/aik-ngx-http";
import {GraphicUtils} from "@aiknow-srl/aik-ngx-common";
import {EventData} from "@aiknow-srl/aik-ngx-eventbus";
import {UserOPEService} from "../../Services/ope/UserOPE.service";
import {ChangePasswordCB} from "../../ope/user/ChangePasswordCB";
import {EventNames} from "../../events/EventBusConstants";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent extends BaseComponent {

  private be: SpringRestService = inject(SpringRestService);
  private route: Router = inject(Router);
  public dialog: MatDialog = inject(MatDialog);
  private platformLocation: PlatformLocation = inject(PlatformLocation);

  graphicUtils = GraphicUtils;

  env = environment.typeOfEnv;

  languages = this.gmService.localeList;

  public isAlarmedProduct = new Array<string>();

  @Input('componentName') componentName?: number;
  @Input('drawer') drawer!: MatSidenav;

  /**
   * Print FE and BE environments in topbar
   */
  printEnvironments(): string {
    let res = '';

    if (environment.typeOfEnv !== 'prod' || this.gmService.envIdLabel !== '') {
      res = `${res} [`;
    }

    if (environment.typeOfEnv !== 'prod') {
      res = `${res} FE: ${environment.typeOfEnv}`;
    }

    if (environment.typeOfEnv !== 'prod' && this.gmService.envIdLabel !== '') {
      res = `${res} -`;
    }

    if (this.gmService.envIdLabel !== '') {
      res = `${res} BE: ${this.gmService.envIdLabel}`;
    }

    if (environment.typeOfEnv !== 'prod' || this.gmService.envIdLabel !== '') {
      res = `${res} ]`;
    }

    return res;

  }

  /**
   * Logout function
   */
  logout(): void {
    this.be.doLogout();
    localStorage.removeItem('language');
    this.route.navigateByUrl('', {skipLocationChange: true});
  }

  /**
   * Opens another browser tab with the documentation
   */
  gotoDoc(): void {
    const baseHref = this.platformLocation.getBaseHrefFromDOM();
    const path = window.location.origin + baseHref + 'doc/';
    window.open(path, '_blank');
  }

  /**
   * Changes the used language
   * @param locale the selected locale
   */
  changeLanguage(locale: string | undefined): void{
    if (!!locale) {
      this.translate.use(locale);
      this.be.setLanguage(locale);
      localStorage.setItem('language', locale);
    }
  }

  /**
   * Opens the change Password dialog
   */
  openChangePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.translate.get('CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESSFUL').subscribe(
            (result) => {
              this.appService.openSuccessSnackbar(result);
            }
          );
        }
      }, error => {
        console.log(error);
      }
    );
  }

  refreshElements() {
    this.eventBus.emit(new EventData(EventNames.REFRESH, null));
  }

  isUnloadedComponent() {
    return !!this.appService.menuVoice && this.appService.menuVoice.name === 'unloadedTransportItems';
  }
}

/*--------------------------------
    CHANGE PASSWORD COMPONENT
 -------------------------------*/

@Component({
  templateUrl: 'change-password-dialog.html',
  selector: 'app-change-password-dialog'
})
export class ChangePasswordDialogComponent extends BaseComponent {

  // @ts-ignore
  username: string;
  // @ts-ignore
  oldPassword: string;
  // @ts-ignore
  newPassword: string;
  // @ts-ignore
  confirmPassword: string;

  private be = inject(SpringRestService);
  private dialog = inject(MatDialogRef<ChangePasswordDialogComponent>);
  private userService: UserOPEService = inject(UserOPEService);


  constructor(@Inject(MAT_DIALOG_DATA) public baseFeHref: string) {
    super();
    this.dialog.disableClose = true;
  }

  async changePassword(): Promise<void> {

    if (this.newPassword === this.confirmPassword) {
      // ****************** SECURING LOGIN **********************
      const oldHashedPassword = this.be.hashPassword(this.oldPassword);
      const newHashedPassword = this.be.hashPassword(this.newPassword);
      // *********************************************************

      try {
        const cb = new ChangePasswordCB();
        // TODO
        // cb.i.oldHashedPassword = oldHashedPassword;
        // cb.i.newHashedPassword = newHashedPassword;
        // cb.i.email = this.username;

        await this.userService.changePassword(cb);

        this.dialog.close(true);

      } catch (error: any) {
        this.appService.openErrorSnackbar(error);
      }

    } else {
      this.appService.openTranslatedErrorSnackbar('ERROR.PASSWORDS_MISMATCH');
    }



  }
}

