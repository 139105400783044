import {CelOPEEnum} from "./CelOPEEnum";
import {BasePageI, BasePageO, PaginatedBaseCB} from "../PaginatedBaseCB";


/**
 * I inner class
 */
class I extends BasePageI {
  celNum?: string;
  celYear?: number;
  procuringStation?: string;
  subject?: string;
  categories?: number[];
}

/**
 * O inner class
 */

class O extends BasePageO {
  cels!: CelDTO[];
}

class CelDTO {
  id!: number;
  celDate!: number;
  celNum!: string;
  procuringStation!: string;
  subject!: string;
  cig?: string;
  startWorkDate!: number;
  endWorkDate!: number;
  accountedAmount!: number;
  celFile!: string;
}


/**
 * put reseller class
 */

export class GetCelListCB extends PaginatedBaseCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(CelOPEEnum.GetCelList);
    this.i = new I();
    this.o = new O();
  }

}
