import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "../../BaseComponent";
import {CelOPEService} from "../../../Services/ope/CelOPE.service";
import {Cel} from "../../../models/cel/Cel";
import {MatTableDataSource} from "@angular/material/table";
import {CelCategory} from "../../../models/cel/CelCategory";
import {GetCelCategoryListCB} from "../../../ope/cel/GetCelCategoryListCB";

@Component({
  selector: 'app-cel-category-registry',
  templateUrl: './cel-category-registry.component.html',
  styleUrls: ['./cel-category-registry.component.css']
})
export class CelCategoryRegistryComponent extends BaseComponent implements OnInit {

  celOPEService = inject(CelOPEService);

  celCategories: CelCategory[] = new Array<CelCategory>();
  selectedRow?: CelCategory;
  displayedColumns: string[] = ['code', 'description', 'addAlsoSubcontractorsAmount'];
  dataSource!: MatTableDataSource<CelCategory>;

  override ngOnInit() {
    super.ngOnInit();

    this.loadData();
  }

  canRead() {
    return true;
  }

  loadData() {

    this.getCelCategoryList();

  }

  getCelCategoryList() {

    const cb = new GetCelCategoryListCB();

    this.celOPEService.getCelCategoryList(cb).then((_) => {
      this.celCategories = cb.o.categories;
      this.dataSource = new MatTableDataSource<CelCategory>(this.celCategories);
    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });

  }
}
