import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./Components/login/login.component";
import {HomeComponent} from "./Components/home/home.component";
import {RouteGuardService} from "./Services/RouteGuard.service";
import {Paths} from "./utils/paths";
import {EmptyComponent} from "./Components/empty/empty.component";
import {CelRegistryComponent} from "./Components/cel/cel-registry/cel-registry.component";
import {CelCategoryRegistryComponent} from "./Components/cel/cel-category-registry/cel-category-registry.component";
import {CelCompanyRegistryComponent} from "./Components/cel/cel-company-registry/cel-company-registry.component";
import {CelDashboardComponent} from "./Components/cel/dashboard/cel-dashboard.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/:reason',
    component: LoginComponent,
  },
  {
    path: '',
    redirectTo: 'login/',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [
      RouteGuardService
    ],
    children: [
      {
        path: Paths.CEL_DASHBOARD,
        component: CelDashboardComponent,
        canActivate: [
          RouteGuardService
        ]
      },
      {
        path: Paths.CEL_REGISTRY,
        component: CelRegistryComponent,
        canActivate: [
          RouteGuardService
        ]
      },
      {
        path: Paths.CEL_CATEGORY_REGISTRY,
        component: CelCategoryRegistryComponent,
        canActivate: [
          RouteGuardService
        ]
      },
      {
        path: Paths.COMPANY_REGISTRY,
        component: CelCompanyRegistryComponent,
        canActivate: [
          RouteGuardService
        ]
      },
      {
        path: Paths.EMPTY,
        component: EmptyComponent,
        canActivate: [
          RouteGuardService
        ]
      },
      { path: '**', redirectTo: Paths.EMPTY }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
