import {CelOPEEnum} from "./CelOPEEnum";
import {BasePageI, BasePageO, PaginatedBaseCB} from "../PaginatedBaseCB";
import {BaseSpringServiceCB} from "@aiknow-srl/aik-ngx-http";


/**
 * I inner class
 */
class I {
  startDate?: number;
  endDate?: number;
  considerAlsoSubContractors!: boolean;
  categoryId!: number;
}

/**
 * O inner class
 */

class O {
  cels!: CelValueDTO[];
}

class CelValueDTO {
  id!: number;
  celDate!: number;
  celNum!: string;
  procuringStation!: string;
  subject!: string;
  cig?: string;
  startWorkDate!: number;
  endWorkDate!: number;
  totalAmount!: number;
  rows!: CelRowDTO[];
}

class CelRowDTO {
  id!: number;
  taxCode!: string;
  companyName!: string;
  amount!: number;
  isSubcontractor!: boolean;
}


/**
 * put reseller class
 */

export class GetCategoryDetailsCB extends BaseSpringServiceCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(CelOPEEnum.GetCategoryDetails);
    this.i = new I();
    this.o = new O();
  }

}
