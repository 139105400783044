<h2 mat-dialog-title>
  <span >{{'PDF_VIEWER.TITLE' | translate }}</span>
</h2>
<mat-dialog-content class="mat-typography" style="height: 750px !important;">
  <embed #pdfViewer type="application/pdf" [src]="fileUrl" class="pdfViewer"/>
</mat-dialog-content>


<!--  *****************************************  -->
<!--    CLOSE/SAVE DIALOG ACTION BUTTON          -->
<!--  *****************************************  -->
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null">{{'BUTTONS.CLOSE' | translate }}</button>
</mat-dialog-actions>
