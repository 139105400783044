<!-- ******************************* -->
<!--            LOGIN FORM           -->
<!-- ******************************* -->
<div class="parent" *ngIf="isCheckedToken">
  <div class="fullHeight">
    <mat-card class="loginCard">

      <div *ngIf="!isMediumScreen">
        <!-- ******************************* -->
        <!--              LOGO               -->
        <!-- ******************************* -->
        <img src="assets/mdr25.png"
             fetchpriority="high"
             class="loginLogo centeredElement"
             alt="mdr_logo"/>
      </div>
      <div class="loginFormContainer" [style.min-width.px]="(isMobile) ? 250 : 335"
           [style.max-width.px]="(isMobile) ? 300 : 400">
        <!-- ******************************* -->
        <!--     ENVIRONMENT INDICATION      -->
        <!-- ******************************* -->

        <div *ngIf="isMediumScreen">
          <!-- ******************************* -->
          <!--              LOGO               -->
          <!-- ******************************* -->
          <img src="assets/mdr25.png"
               fetchpriority="high"
               class="loginLogoMobile centeredElement"
               alt="mdr_logo"/>
        </div>

        <h3 class="subLogoLogin loginTitle">{{appService.APP_NAME}}</h3>

        <!--        <h3 class="centeredText loginSubTitle">{{'LOGIN.LOGIN_MESSAGE' | translate }}</h3>-->
        <form #loginForm="ngForm" class="loginFormFields">

          <!-- ******************************* -->
          <!--       USERNAME INPUT FIELD      -->
          <!-- ******************************* -->
          <mat-form-field class="fullWidthField">
            <mat-label>{{'LOGIN.USERNAME' | translate }}</mat-label>
            <input matInput required #userInput="ngModel" name="username" [(ngModel)]="username">
            <mat-error *ngIf="userInput.invalid">
              {{'LOGIN.USERNAME_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>

          <!-- ******************************* -->
          <!--       PASSWORD INPUT FIELD      -->
          <!-- ******************************* -->
          <mat-form-field class="fullWidthField">
            <mat-label>{{'LOGIN.PASSWORD' | translate }}</mat-label>
            <input type="password" matInput required #pswdInput="ngModel" name="password" [(ngModel)]="password">
            <mat-error *ngIf="pswdInput.invalid">
              {{'LOGIN.PASSWORD_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>

          <div class="loginFormControls">

            <!-- ******************************* -->
            <!--         REMEMBER ME FLAG        -->
            <!-- ******************************* -->
            <mat-slide-toggle color="primary"
                              [labelPosition]="LABEL_POSITION"
                              [(ngModel)]="rememberMeFlag"
                              name="rememberMeToggle"
                              class="toggleMargins">
              {{'LOGIN.REMEMBER_ME' | translate }}
            </mat-slide-toggle>

            <!-- ******************************* -->
            <!--          LOGIN BUTTON           -->
            <!-- ******************************* -->
            <button mat-raised-button color="primary" class="primaryButton loginButton"
                    (click)="awaitLogin()">{{'LOGIN.LOG_IN' | translate }}</button>

          </div>

        </form>
      </div>


    </mat-card>
  </div>

  <div class="rightTop">
    <h3 class="envType" *ngIf="printEnvironments() !== 'prod'">Environment: {{printEnvironments()}}</h3>
  </div>
</div>

