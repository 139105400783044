import {BaseSpringServiceCB} from "@aiknow-srl/aik-ngx-http";
import {CelOPEEnum} from "./CelOPEEnum";


/**
 * I inner class
 */
class I {
  id!: number;

}

/**
 * O inner class
 */

class O {

}

/**
 * put reseller class
 */

export class DelCelRowCB extends BaseSpringServiceCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(CelOPEEnum.DelCelRow);
    this.i = new I();
    this.o = new O();
  }

}
