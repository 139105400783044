import {inject, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

/**
 *  The pipe converts an unsafe url in a safe one
 */
@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  private sanitizer = inject(DomSanitizer);

  transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
