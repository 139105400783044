import {inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor() { }

  canActivate() {
    return true;
  }
}
