import {Component, inject} from '@angular/core';
import {SpringRestService} from "@aiknow-srl/aik-ngx-http";
import {BaseComponent} from "./Components/BaseComponent";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent {

  private be = inject(SpringRestService);

  private DEFAULT_LANGUAGE = 'it';

  constructor() {
    super();

    this.appService.printStartingAppInfosInConsole();

    // PREVENT USER TO USE BACK AND FF BUTTONS
    history.pushState(null, 'null', window.location.href);

    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, 'null', window.location.href);
      return false;
    });

    // SET DEFAULT LANGUAGE TO BROWSER LANGUAGE OR 'en'
    const browserLang = this.translate.getBrowserLang();
    if (!!browserLang && this.translate.getLangs().includes(browserLang)) {
      this.translate.setDefaultLang(browserLang);
    } else {
      this.translate.setDefaultLang(this.DEFAULT_LANGUAGE);
    }
    // SET LANGUAGE IF STORED IN LOCAL STORAGE
    if (localStorage.getItem('language') !== null) {
      this.translate.use(localStorage["language"]);
      this.be.setLanguage(localStorage["language"]);
    } else {
      this.translate.use(this.DEFAULT_LANGUAGE);
      this.be.setLanguage(this.DEFAULT_LANGUAGE);
    }
  }
}
