import {Injectable} from '@angular/core';
import {BaseOPEService} from "./BaseOPEService";
import {firstValueFrom} from "rxjs";
import {PostLoginCB} from "../../ope/user/PostLoginCB";
import {ChangePasswordCB} from "../../ope/user/ChangePasswordCB";

@Injectable({
  providedIn: 'root'
})
export class UserOPEService extends BaseOPEService {

  public postLogin(cb: PostLoginCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  public changePassword(cb: ChangePasswordCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }


}
