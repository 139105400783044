
export class Cel {
  id!: number;
  celDate!: number;
  celNum!: string;
  procuringStation!: string;
  subject!: string;
  cig?: string;
  startWorkDate!: number;
  endWorkDate!: number;
  accountedAmount!: number;
  celFile!: string;
}
