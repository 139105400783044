import {Menu} from './Menu';
import {MenuGroup} from "./MenuGroup";

export class PostLoginUser {
  id!: number;
  /**
   * Username of logged customerUser
   */
  username!: string;
  /**
   * First Name of logged customerUser
   */
  firstname!: string;
  /**
   * Last Name of logged customerUser
   */
  lastname!: string;
  /**
   * Language of logged customerUser
   */
  language?: string;
  /**
   * Roles of logged customerUser
   */
  roles?: string[];
  /**
   * List of menuItems items
   */
  menuItems?: MenuGroup[];

}
