import {BaseCB, BaseSpringServiceCB} from "@aiknow-srl/aik-ngx-http";

export class BasePageI {
  public pageSize!: number;
  public page!: number;
}

export class BasePageO {
  public totalElements!: number;
  public totalPages!: number;
}

export class PaginatedBaseCB extends BaseSpringServiceCB {
  override i: BasePageI;
  override o: BasePageO;

  constructor(serviceName: string) {
    super(serviceName);

    this.i = new BasePageI();
    this.o = new BasePageO();
  }
}
