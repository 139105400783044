import {CelOPEEnum} from "./CelOPEEnum";
import {BaseSpringServiceCB} from "@aiknow-srl/aik-ngx-http";


/**
 * I inner class
 */
class I {
  celNum?: string;
  celYear?: number;
  procuringStation?: string;
  subject?: string;
  categories?: number[];
}

/**
 * O inner class
 */

class O {
  categories!: CelCategoryDTO[];
}

class CelCategoryDTO {
  id!: number;
  code!: string;
  description!: string;
  addAlsoSubcontractorsAmount!: boolean;
}


/**
 * put reseller class
 */

export class GetCelCategoryListCB extends BaseSpringServiceCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(CelOPEEnum.GetCelCategoryList);
    this.i = new I();
    this.o = new O();
  }

}
