import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {UserOPEService} from "../../Services/ope/UserOPE.service";
import {SpringRestService} from "@aiknow-srl/aik-ngx-http";
import {PostLoginCB} from "../../ope/user/PostLoginCB";
import {BaseComponent} from "../BaseComponent";
import {Paths} from "../../utils/paths";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {
  // *******************************
  //          LOGIN PARAMS
  // *******************************
  /**
   * field that contains the username inserted by customerUser
   */
  username = '';
  /**
   * field that contains the password inserted by customerUser
   */
  password = '';
  /**
   * Remember me Flag
   */
  rememberMeFlag = false;
  /**
   * Material Toggle Position
   */
  readonly LABEL_POSITION = 'after';
  /**
   * Flag true if token has been checked
   */
  isCheckedToken = false;
  /**
   * field that contains the password inserted by customerUser
   */
  baseFeHref = '';


  /**
   * Reference to template login form
   */
  @ViewChild('loginForm') private loginForm?: NgForm;

  private be = inject(SpringRestService);
  private userService = inject(UserOPEService);
  private route = inject(Router);
  public dialog = inject(MatDialog);

  // *****************************************
  //     COMPONENT ONINIT
  // *****************************************

  /**
   * OnInit method
   */
  override ngOnInit(): void {
    super.ngOnInit();
    // SET LANGUAGE
    this.be.setLanguage('it');

    this.baseFeHref = window.location.href;

    this.logger.debug('LoginComponent initialized');

    this.doPostLogin();
  }

  async doPostLogin() {
    if (!!this.be.getToken()) {
      try {
        const cb = new PostLoginCB();
        await this.userService.postLogin(cb);
        // STORING ALL POSTLOGIN PARAMETERS IN GLOBAL MODEL
        this.appService.storeParamsIntoGlobalModel(cb);
        this.logger.log('User auto-logged with valid token');
        this.isCheckedToken = true;
        await this.route.navigateByUrl(Paths.HOME, {skipLocationChange: true});
      } catch (err: any) {
        // EXPIRED TOKEN ERROR: STAY IN LOGIN
        this.logger.error(err);
        this.isCheckedToken = true;
        await this.route.navigateByUrl(Paths.LOGIN, {skipLocationChange: true});
      }
    } else {
      this.isCheckedToken = true;
    }
  }

  // *******************************
  //         COMPONENT METHODS
  // *******************************


  async awaitLogin() {
    await this.login();
  }

  /**
   * the method calls the login service with the customerUser credentials
   */
  async login() {
    this.logger.log(`User ${this.username} presses login button`);
    this.appService.closeSnackbar();
    if (!!this.loginForm && !this.loginForm.invalid) {
      try {
        firstValueFrom(this.be.login(this.username, this.password, this.appService.composeUrl(), this.rememberMeFlag, true))
          .then((success) => {
            console.log(success)
            try {
              // If ok, try to call postLogin service
              const cb = new PostLoginCB();
              this.userService.postLogin(cb).then(() => {
                // STORING ALL POSTLOGIN PARAMETERS IN GLOBAL MODEL
                this.appService.storeParamsIntoGlobalModel(cb);
                this.logger.log(`Login succeeded!`);
                this.route.navigateByUrl(Paths.HOME, {skipLocationChange: true});
              });
            } catch (err: any) {
              // UNKNOWN ERROR: STAY IN LOGIN
              this.logger.error(err);
              this.be.clearTokens();
              this.appService.openErrorSnackbar(err);
            }
          });
      } catch (error: any) {
        this.logger.error(error);
        this.username = '';
        this.password = '';
        if (error.statusCode === 200) {
          // It's an applicative error
          // Work-around
          // if the error contains " ", it's not a real applicative error
          if (!!error.error && error.error.includes(' ')) {
            this.appService.openTranslatedErrorSnackbar((!!error.error) ? error.error : 'ERROR.CREDENTIALS_ERROR');
          } else {
            this.appService.openTranslatedErrorSnackbar((!!error.error) ? 'BE_ERROR.' + error.error : 'ERROR.CREDENTIALS_ERROR');
          }
        } else {
          // It's an HTTP error

          if (!!error.error) {
            this.appService.openErrorSnackbar(error.error);

          } else {
            this.appService.openTranslatedErrorSnackbar('ERROR.GENERIC');
          }


        }
      }
    } else {
      // INVALID FORM, VALIDATION HANDLED IN HTML
    }
  }

  /**
   * Print BE environment in home card
   */
  printEnvironments(): string {
    return environment.typeOfEnv;
  }

  // openForgotPassword(): void {
  //   const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
  //     width: '600px',
  //     data: this.baseFeHref
  //   });
  //
  //   dialogRef.afterClosed().subscribe(
  //     (result) => {
  //       if (result !== null) {
  //         this.appService.openTranslatedSuccessSnackbar('ALERT.RESET-PWD-EMAIL-SENT');
  //       }
  //
  //     }, error => {
  //       this.logger.error(error);
  //     }
  //   );
  // }
}
