import {Component, Inject, inject, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../BaseComponent";
import {CelOPEService} from "../../../Services/ope/CelOPE.service";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {CelCompany} from "../../../models/cel/CelCompany";
import {GetCompanyListCB} from "../../../ope/cel/GetCompanyListCB";
import {PageEvent} from "@angular/material/paginator";
import {NgForm} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PutCompanyCB} from "../../../ope/cel/PutCompanyCB";

@Component({
  selector: 'app-cel-company-registry',
  templateUrl: './cel-company-registry.component.html',
  styleUrls: ['./cel-company-registry.component.css']
})
export class CelCompanyRegistryComponent extends BaseComponent implements OnInit {

  celOPEService = inject(CelOPEService);
  public dialog = inject(MatDialog);

  companies: CelCompany[] = new Array<CelCompany>();
  displayedColumns: string[] = ['taxCode', 'companyName', 'actions'];
  dataSource!: MatTableDataSource<CelCompany>;

  override ngOnInit() {
    super.ngOnInit();

    this.loadData();
  }


  canRead() {
    return true;
  }

  loadData() {
    this.getCompanyList();

  }

  getCompanyList() {

    const cb = new GetCompanyListCB();
    cb.i.page = this.page;
    cb.i.pageSize = this.pageSize;

    this.celOPEService.getCompanyList(cb).then((_) => {
      this.companies = cb.o.companies;
      this.totalPages = cb.o.totalPages;
      this.totalElements = cb.o.totalElements;
      this.dataSource = new MatTableDataSource<CelCompany>(this.companies);
    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });
  }

  editCompany(element: CelCompany) {
    const dialogRef = this.dialog.open(UpdateCompanyDialogComponent, {
      width: '60vw',
      data: {
        element: JSON.parse(JSON.stringify(element))
      }
    });

    dialogRef.afterClosed().subscribe(
      async (result: boolean) => {
        await this.getCompanyList();
      }, (error: any) => {
        console.log(error);
      }
    );
  }

  canEdit() {
    return true;
  }


  public getPaginatorData(event: PageEvent): PageEvent {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getCompanyList();
    return event;
  }
}



/*--------------------------------
   UPDATE COMPANY DIALOG Component
 -------------------------------*/

@Component({
  templateUrl: 'update-company-dialog.html',
  selector: 'app-upsert-cel-dialog',
  styleUrls: ['./cel-company-registry.component.css']
})
export class UpdateCompanyDialogComponent extends BaseComponent implements OnInit {

  isSavingData = false;
  element: CelCompany;

  titleData: string = '';

  @ViewChild('updateCompanyForm') updateCompanyForm!: NgForm;

  private dialog = inject(MatDialogRef<UpdateCompanyDialogComponent>);
  private celOpeService: CelOPEService = inject(CelOPEService);


  constructor(@Inject(MAT_DIALOG_DATA) public data: any)
  {
    super();
    this.dialog.disableClose = true;
    this.element = data.element;

    const theCompany: CelCompany = JSON.parse(JSON.stringify(this.element));

    this.titleData = theCompany.companyName;

  }

  override ngOnInit() {
    super.ngOnInit();

  }

  saveCompany() {

    const cb = new PutCompanyCB();

    cb.i.id = this.element.id;
    cb.i.companyName = this.element.companyName;
    cb.i.taxCode = this.element.taxCode;

    this.celOpeService.putCompany(cb).then((_) => {
      this.appService.openTranslatedSuccessSnackbar('UPDATE_COMPANY.COMPANY_UPDATE_SUCCESS');

      this.dialog.close();
    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });

  }

}
