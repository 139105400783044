<div class="fullPageContainer">
  <div class="fillPage" style="display: flex; flex-direction: column" *ngIf="canRead()">

    <div [ngClass]="(isSmallScreen) ? 'rowFlexContent' : 'columnFlexContent'" fxLayoutGap="10px grid"
         fxLayoutGap.lt-lg="0 grid"
         class="fullWidthField">
      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      &lt;!&ndash;             BARCODE FILTER             &ndash;&gt;-->
      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      <mat-form-field fxFlex appearance="outline">-->
      <!--        <mat-label>{{'PROCESSING_VIEW.FILTER_BY_BARCODE' | translate }}</mat-label>-->
      <!--        <input matInput [(ngModel)]="barcodeToFind"-->
      <!--               name="serialFilter">-->
      <!--        <mat-icon matPrefix>search</mat-icon>-->
      <!--      </mat-form-field>-->

      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      &lt;!&ndash;             FROM-DATE FILTER           &ndash;&gt;-->
      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      <mat-form-field fxFlex appearance="outline">-->
      <!--        <mat-label>{{'PROCESSING_VIEW.FILTER_BY_FROMDATE' | translate }}</mat-label>-->
      <!--        <input matInput [matDatepicker]="bgDateS" (dateChange)="assignBGDate($event)" [value]="beginDate">-->
      <!--        <mat-datepicker-toggle matSuffix [for]="bgDateS"></mat-datepicker-toggle>-->
      <!--        <mat-datepicker #bgDateS></mat-datepicker>-->
      <!--      </mat-form-field>-->

      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      &lt;!&ndash;               TO-DATE FILTER           &ndash;&gt;-->
      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      <mat-form-field fxFlex appearance="outline">-->
      <!--        <mat-label>{{'PROCESSING_VIEW.FILTER_BY_TODATE' | translate }}</mat-label>-->
      <!--        <input matInput [matDatepicker]="eDateS" (dateChange)="assignEDate($event)" [value]="endDate">-->
      <!--        <mat-datepicker-toggle matSuffix [for]="eDateS"></mat-datepicker-toggle>-->
      <!--        <mat-datepicker #eDateS></mat-datepicker>-->
      <!--      </mat-form-field>-->

      <!--      <div fxFlex="8%">-->
      <!--        <button mat-raised-button color="primary" class="findButton"-->
      <!--                (click)="getProcessingCoils(true)">{{'BUTTON.SEARCH' | translate }}</button>-->
      <!--      </div>-->
    </div>

    <div style="flex: 1 1; position:relative;">
      <div class="mat-elevation-z8 fillPage" *ngIf="!!cels">
        <div class="fullPageContainer" style="display: flex;flex-direction: column;background: white;">
          <div class="full-table-container" style="flex: 1 1; background: white;">
            <div class="tableHeader">
              <button (click)="getCelsList()" mat-icon-button aria-label="Refresh" [title]="'CEL.TABLE.REFRESH' | translate" color="primary">
                <mat-icon>refresh</mat-icon>
              </button>
              <button (click)="addCel()" [disabled]="!canEditCels()" [title]="'CEL.TABLE.ADD' | translate" mat-icon-button aria-label="Add CEL"
                      color="primary">
                <mat-icon>add</mat-icon>
              </button>
            </div>

            <div style="display: flex; flex-direction: column; height: 100%; overflow: auto">
              <table mat-table [dataSource]="dataSource" aria-describedby="cels" multiTemplateDataRows>

                <!-- ID Column -->
                <ng-container matColumnDef="id" hidden="true">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.ID' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <!-- CEL_NUM Column -->
                <ng-container matColumnDef="celNum">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.CEL_NUM' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.celNum}} </td>
                </ng-container>

                <!-- CEL_DATE Column -->
                <ng-container matColumnDef="celDate">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.CEL_DATE' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{printDate(element.celDate)}} </td>
                </ng-container>

                <!-- PROCURING_STATION Column -->
                <ng-container matColumnDef="procuringStation">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.PROCURING_STATION' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.procuringStation}} </td>
                </ng-container>

                <!-- SUBJECT Column -->
                <ng-container matColumnDef="subject">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.SUBJECT' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.subject}} </td>
                </ng-container>

                <!-- CIG Column -->
                <ng-container matColumnDef="cig">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.CIG' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.cig}} </td>
                </ng-container>

                <!-- START_WORK_DATE Column -->
                <ng-container matColumnDef="startWorkDate">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.START_WORK_DATE' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{printDate(element.startWorkDate)}} </td>
                </ng-container>

                <!-- END_WORK_DATE Column -->
                <ng-container matColumnDef="endWorkDate">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.END_WORK_DATE' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{printDate(element.endWorkDate)}} </td>
                </ng-container>

                <!-- ACCOUNTED_AMOUNT Column -->
                <ng-container matColumnDef="accountedAmount">
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.ACCOUNTED_AMOUNT' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.accountedAmount | currency : "EUR": "symbol"}} </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions" stickyEnd>
                  <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.ACTIONS.TITLE' | translate}} </th>
                  <td mat-cell *matCellDef="let element">

                    <button title="Funzioni" color="primary"
                            mat-icon-button
                            *ngIf="isMediumScreen"
                            (click)="$event.stopPropagation();"
                            [matMenuTriggerFor]="matMenu">
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #matMenu="matMenu">

                      <button mat-menu-item
                              [title]="'CEL.TABLE.ACTIONS.DOWNLOAD' | translate"
                              (click)="downloadCel(element); $event.stopPropagation();">
                        <mat-icon color="primary">description</mat-icon>
                        {{'CEL.TABLE.ACTIONS.DOWNLOAD' | translate}}
                      </button>

                      <button mat-menu-item
                              [title]="'CEL.TABLE.ACTIONS.EDIT' | translate"
                              [disabled]="!canEdit()"
                              (click)="editCel(element); $event.stopPropagation();">
                        <mat-icon color="primary">edit</mat-icon>
                        {{'CEL.TABLE.ACTIONS.EDIT' | translate}}
                      </button>

                      <button mat-menu-item
                              [title]="'CEL.TABLE.ACTIONS.DELETE' | translate" [disabled]="!canDelete()"
                              (click)="deleteCel(element); $event.stopPropagation();">
                        <mat-icon color="primary">delete</mat-icon>
                        {{'CEL.TABLE.ACTIONS.DELETE' | translate}}
                      </button>


                    </mat-menu>


                    <button *ngIf="!isMediumScreen" mat-icon-button color="primary"
                            [title]="'CEL.TABLE.ACTIONS.DOWNLOAD' | translate"
                            (click)="downloadCel(element); $event.stopPropagation();">
                      <mat-icon>description</mat-icon>
                    </button>

                    <button *ngIf="!isMediumScreen" mat-icon-button color="primary"
                            [title]="'CEL.TABLE.ACTIONS.EDIT' | translate"
                            [disabled]="!canEdit()"
                            (click)="editCel(element); $event.stopPropagation();">
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button *ngIf="!isMediumScreen" mat-icon-button color="primary"
                            [title]="'CEL.TABLE.ACTIONS.DELETE' | translate"
                            [disabled]="!canDelete()"
                            (click)="deleteCel(element); $event.stopPropagation();">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetailCel">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="noPadding">
                    <div class="example-element-detail"
                         [@detailExpand]="!!selectedRow && element == selectedRow ? 'expanded' : 'collapsed'">

                      <div *ngIf="detailIsLoading" class="fullWidthField">
                        <!--                      <p class="mat-typography centeredText">{{'TABLE.LOADING' | translate}}</p>-->
                        <mat-spinner class="centeredElement" [diameter]="45"></mat-spinner>
                      </div>

                      <div
                        *ngIf="!!celDetails && celDetails.celRows.length === 0 && celDetails.subcontractorCelRows.length === 0">
                        <p style="padding: 10px 30px;"
                           class="mat-typography centeredText">{{'CEL_ROW.NO_DATA_FOUND' | translate}}</p>
                      </div>

                      <div [ngClass]="(isMediumScreen) ? 'celRowMobileTableContainer' : 'celRowTableContainer'"
                           *ngIf="!detailIsLoading && !!celDetails && (celDetails.celRows.length > 0 || celDetails.subcontractorCelRows.length > 0)">

                        <div [ngClass]="(isMediumScreen) ? 'celMobileRowTable' : 'celRowTable'" *ngIf="!!selectedRow">
                          <h4 class="celRowTableTitle">{{'CEL.DETAILS.FOSTERS' | translate }}</h4>
                          <div class="innerTableContainer">
                            <table mat-table [dataSource]="fostersCelRows" aria-describedby="stdTable">

                              <!-- CATEGORY Column -->
                              <ng-container matColumnDef="rowCategory">
                                <th mat-header-cell *matHeaderCellDef> {{'CEL_ROW.TABLE.CATEGORY' | translate}} </th>
                                <td mat-cell *matCellDef="let element">{{element.category.code}}</td>
                              </ng-container>

                              <!-- COMPANY Column -->
                              <ng-container matColumnDef="company">
                                <th mat-header-cell
                                    *matHeaderCellDef> {{'CEL_ROW.TABLE.COMPANY' | translate}} </th>
                                <td mat-cell *matCellDef="let element">{{element.company.companyName}}</td>
                              </ng-container>

                              <!-- AMOUNT Column -->
                              <ng-container matColumnDef="rowAmount">
                                <th mat-header-cell
                                    *matHeaderCellDef> {{'CEL_ROW.TABLE.AMOUNT' | translate}} </th>
                                <td mat-cell
                                    *matCellDef="let element">{{element.amount  | currency : "EUR": "symbol" }}</td>
                              </ng-container>


                              <tr mat-header-row *matHeaderRowDef="fostersCelRowColumns"></tr>
                              <tr mat-row
                                  *matRowDef="let row; columns: fostersCelRowColumns;"></tr>

                              <!--  ************************************  -->
                              <!--               NO DATA ROW              -->
                              <!--  ************************************  -->
                              <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell"
                                    [colSpan]="fostersCelRowColumns.length">{{'COMMON.NO_DATA_FOUND' | translate }}</td>
                              </tr>
                            </table>
                          </div>

                        </div>
                        <div *ngIf="!isMediumScreen" class="celRowTablesPreSeparator"></div>
                        <div *ngIf="!isMediumScreen" class="celRowTablesSeparator"></div>

                        <div [ngClass]="(isMediumScreen) ? 'celMobileRowTable' : 'celRowTable'" *ngIf="!!selectedRow">
                          <h4 class="celRowTableTitle">{{'CEL.DETAILS.SUBCONTRACTORS' | translate }}</h4>

                          <h5 class="celRowTableNotFound"
                              *ngIf="celDetails.subcontractorCelRows.length === 0">{{'COMMON.NO_DATA_FOUND' | translate }}</h5>
                          <div class="innerTableContainer" *ngIf="celDetails.subcontractorCelRows.length > 0">

                            <table mat-table [dataSource]="subContractorsCelRows" aria-describedby="stdTable">

                              <!-- CATEGORY Column -->
                              <ng-container matColumnDef="rowCategory">
                                <th mat-header-cell *matHeaderCellDef> {{'CEL_ROW.TABLE.CATEGORY' | translate}} </th>
                                <td mat-cell *matCellDef="let element">{{element.category.code}}</td>
                              </ng-container>

                              <!-- COMPANY Column -->
                              <ng-container matColumnDef="company">
                                <th mat-header-cell
                                    *matHeaderCellDef> {{'CEL_ROW.TABLE.COMPANY' | translate}} </th>
                                <td mat-cell *matCellDef="let element">{{element.company.companyName}}</td>
                              </ng-container>

                              <!-- FOSTER_COMPANY Column -->
                              <ng-container matColumnDef="fosterCompany">
                                <th mat-header-cell
                                    *matHeaderCellDef> {{'CEL_ROW.TABLE.FOSTER_COMPANY' | translate}} </th>
                                <td mat-cell *matCellDef="let element">{{element.fosterCompany.companyName}}</td>
                              </ng-container>


                              <!-- AMOUNT Column -->
                              <ng-container matColumnDef="rowAmount">
                                <th mat-header-cell
                                    *matHeaderCellDef> {{'CEL_ROW.TABLE.AMOUNT' | translate}} </th>
                                <td mat-cell
                                    *matCellDef="let element">{{element.amount  | currency : "EUR": "symbol" }}</td>
                              </ng-container>


                              <tr mat-header-row *matHeaderRowDef="subContractorsCelRowColumns"></tr>
                              <tr mat-row
                                  *matRowDef="let row; columns: subContractorsCelRowColumns;"></tr>

                              <!--  ************************************  -->
                              <!--               NO DATA ROW              -->
                              <!--  ************************************  -->
                              <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell"
                                    [colSpan]="subContractorsCelRowColumns.length">{{'COMMON.NO_DATA_FOUND' | translate }}</td>
                              </tr>
                            </table>
                          </div>

                        </div>

                      </div>


                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row [class.example-expanded-row]="selectedRow === row" class="example-element-row"
                    [ngClass]="{'highlightRow': !!selectedRow && selectedRow.id === row.id}"
                    (click)="getCelDetails(row)"
                    *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr mat-row *matRowDef="let row; columns: ['expandedDetailCel']" class="example-detail-row"></tr>

                <!--  ************************************  -->
                <!--               NO DATA ROW              -->
                <!--  ************************************  -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell"
                      [colSpan]="displayedColumns.length">{{'COMMON.NO_DATA_FOUND' | translate }}</td>
                </tr>
              </table>
            </div>
          </div>

          <mat-divider></mat-divider>

          <mat-paginator class="paginatorCustom" [pageIndex]="page" (page)="getPaginatorData($event)"
                         [length]="totalElements"
                         [pageSize]="pageSize" [pageSizeOptions]="PAGESIZE_OPTIONS"></mat-paginator>
        </div>

      </div>
    </div>

  </div>
</div>
