import {BaseSpringServiceCB} from '@aiknow-srl/aik-ngx-http';
import {UserOPEEnum} from "./UserOPEEnum";

class PostLoginUserDTO {
  id!: number;
  /**
   * Username of logged customerUser
   */
  username!: string;
  /**
   * First Name of logged customerUser
   */
  firstname!: string;
  /**
   * Last Name of logged customerUser
   */
  lastname!: string;
  /**
   * Language of logged customerUser
   */
  language?: string;
  /**
   * Roles of logged customerUser
   */
  roles?: string[];
  /**
   * List of menuItems items
   */
  menuItems?: MenuGroupDTO[];

}



class MenuGroupDTO {
  /**
   * id
   */
  id!: number;
  /**
   * Name of the menu group
   */
  name!: string;
  /**
   * Enabled roles
   * Empty -> all roles
   */
  roles!: string[];
  /**
   * OrderIndex
   */
  orderIndex!: number;
  /**
   * icon
   */
  voices!: MenuVoiceDTO[];
}

class MenuVoiceDTO {
  /**
   * id
   */
  id!: number;
  /**
   * Name of the menu group
   */
  name!: string;
  /**
   * OrderIndex
   */
  orderIndex!: number;
  /**
   * icon
   */
  functions!: MenuVoiceFunctionDTO[];
}

class MenuVoiceFunctionDTO {
  /**
   * id
   */
  id!: number;
  /**
   * Name of the menu group
   */
  name!: string;
}

class LocaleDTO {
  /**
   * Language locale
   */
  locale!: string;
  /**
   * Country flag
   */
  countryFlag?: string;
}

/**
 * O Inner Class
 */
class O {

  /**
   * Application ID
   */
  appId!: string;
  /**
   * Environment type
   */
  envId!: string;
  /**
   * Versione corrente dell'applicazione
   */
  appVersion!: string;
  /**
   * Environment Label type
   */
  envIdLabel!: string;
  /**
   * Logged User
   */
  user!: PostLoginUserDTO;
  /**
   * Languages list
   */
  localeList!: LocaleDTO[];

}

export class EnumDTO {
  key!: string;
  val!: string;
}

class ViewConditionDTO {
  name!: string;
  symbol!: string;
}

/**
 * I Inner Class
 */
class I {

}

/**
 * GetTransportItemDetailCB class
 */
export class PostLoginCB extends BaseSpringServiceCB {
  /**
   * Input
   */
  public override i: I;
  /**
   * Output
   */
  public override o: O;

  /**
   * Class constructor
   */
  constructor() {
    super(UserOPEEnum.PostLogin);
    this.i = new I();
    this.o = new O();
  }

}
