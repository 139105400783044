import {inject, Injectable, NgZone} from '@angular/core';
import {BaseService} from "./BaseService";
import {GlobalModelService} from "./GlobalModel.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../environments/environment";
import {PostLoginCB} from "../ope/user/PostLoginCB";
import {Menu} from "../models/user/Menu";
import {GraphicUtils} from "@aiknow-srl/aik-ngx-common";
import {MenuVoice} from "../models/user/MenuVoice";
import {MenuGroup} from "../models/user/MenuGroup";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AppService extends BaseService {

  private gmService: GlobalModelService = inject(GlobalModelService);
  private messageSnackBar: MatSnackBar = inject(MatSnackBar);

  readonly APP_NAME = "Edilis Web";

  public menuVoice?: MenuVoice;
  public menuGroup?: MenuGroup;

  private readonly _EMAIL_PATTERN_VALIDATOR = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,4})+$';

  // ****************************
  //      SNACKBAR PARAMS
  // ****************************
  /**
   * Undefined duration of snackbar
   */
  private readonly UNDEFINED_SNACKBAR_DURATION = undefined;
  /**
   * 5 sec duration of snackbar
   */
  private readonly TWO_SECONDS_SNACKBAR_DURATION = 2000;
  /**
   * Horizontal sknackbar position
   */
  private readonly HORIZONTAL_SNACKBAR_POSITION = 'center';
  /**
   * Vertical sknackbar position
   */
  private readonly VERTICAL_SNACKBAR_POSITION = 'bottom';

  // ****************************
  // RESPONSIVE THRESHOLD PARAMS
  // ****************************
  /**
   * Mobile screen width threshold
   */
  public readonly MOBILE_SCREEN_THRESHOLD = 570;
  /**
   * Small screen width threshold
   */
  public readonly SMALL_SCREEN_THRESHOLD = 850;
  /**
   * Medium screen width threshold
   */
  public readonly MEDIUM_SCREEN_THRESHOLD = 1200;
  /**
   * Big screen width threshold
   */
  public readonly BIG_SCREEN_THRESHOLD = 1800;


  // ----------------------------------
  //     GLOBAL APP METHODS
  // ----------------------------------

  // Run the function only in the browser
  browserOnly(platformId: Object, zone: NgZone, f: () => void) {

    f();

    // if (isPlatformBrowser(platformId)) {
    //   zone.runOutsideAngular(() => {
    //     f();
    //   });
    // }
  }

  /**
   * The method prints AIknow infos in the console
   */
  printStartingAppInfosInConsole() {

    this.logger.info("\n" +
      "\t Contact: \t" + "admin@impresamdr.it     \n" +
      "\t Website: \t" + "https://impresamdr.it     \n" +
      "\t App Name: \t" + this.APP_NAME + "           \n");

  }


  printTsDateInItalianFormat(ts: number | undefined, alsoTime= true): string {

    if (!!ts) {
      return this.printDateTimeInItalianFormat(new Date(ts), alsoTime);
    }
    return '';

  }

  printDateTimeInItalianFormat(date: Date, alsoTime= true): string {
    let result = '';

    if (!!date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      result = `${day}/${month}/${year}`;

      if (alsoTime) {
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');

        result += ` ${hour}:${minute}:${second}`;
      }



    }

    return result;
  }


  /**
   * The method composes the base URL of the BE
   */
  public composeUrl(): string {
    return `${environment.APIProtocol}${environment.APIIp}:${environment.APIPort}${environment.APIContext}`;
  }

  /**
   * The method composes the base URL of the BE for cel files
   */
  public composeGetFileUrl(filePath: string): string {
    return `${environment.APIProtocol}${environment.APIIp}:${environment.APIPort}${environment.APIContextBase}/files/${filePath}`;
  }

  /**
   * The method composes the base URL of the BE for lspin images
   */
  public composeGetLspinImageUrl(filePath: string): string {
    return `${environment.APIProtocol}${environment.APIIp}:${environment.APIPort}/lspinphotos/${filePath}`;
  }

  /**
   * The method composes the base URL of the BE
   */
  public composePutImageUrl(serviceName: string): string {
    return `${this.composeUrl()}/${serviceName}`;
  }


  composeText(maxLength: number = 200): RegExp {

    if (maxLength === 15) {
      return new RegExp(/^.{0,15}$/g);
    } else if (maxLength === 16) {
      return new RegExp(/^.{0,16}$/g);
    } else if (maxLength === 10) {
      return new RegExp(/^.{0,10}$/g);
    } else if (maxLength === 100) {
      return new RegExp(/^.{0,100}$/g);
    } else if (maxLength === 500) {
      return new RegExp(/^.{0,500}$/g);
    } else if (maxLength === 200) {
      return new RegExp(/^.{0,200}$/g);
    } else if (maxLength === 2) {
      return new RegExp(/^.{0,2}$/g);
    } else if (maxLength === 11) {
      return new RegExp(/^.{0,11}$/g);
    } else if (maxLength === 25) {
      return new RegExp(/^.{0,25}$/g);
    } else {
      return new RegExp(/^.{0,15}$/g);
    }

  }

  /**
   * THe method stores the PostLoginCB parameters into the GlobalModelService and logs some info data
   * @param cb
   */
  storeParamsIntoGlobalModel(cb: PostLoginCB): void {
    this.gmService.storeParamsIntoGlobalModel(cb);

    this.logger.info("\n\t App ver: \t" +  this.gmService.getAppVersion() + "         \n" +
      "\t App env: \t" + cb.o.envIdLabel + "\n" +
      "\t User: \t\t" + cb.o.user.username + "    \n")
  }


  customLabelMethod(field: string, data: any) {
    let target = field + '.' + data.text;
    let label = this.translate.instant(target)
    if (label === target || !label)
      return target;
    return label
  }

  customHintMethod(field: string, data: any) {
    let target = field + '.HELP_TEXTS.' + data.text;
    let tooltip = this.translate.instant(target);
    if (tooltip === target || !tooltip)
      return false;
    return tooltip
  }

  // *******************************
  //      MAT SNACKBAR METHODS
  // *******************************
  /**
   * The method opens a snackBar with success message and style.
   * The default duration is 2 seconds
   * The default position is bottom-centered
   * @param messageID the message to display
   */
  public openTranslatedSuccessSnackbar(messageID: string): void {
    this.translate.get(messageID).subscribe(
      translation => {
        this.openSuccessSnackbar(translation);
      },
      error1 => {
        console.log(error1);
        this.openSuccessSnackbar(messageID);
      });
  }

  public openSuccessSnackbar(message: string): void {
    this.messageSnackBar.open(message, 'Chiudi', {
      duration: this.TWO_SECONDS_SNACKBAR_DURATION,
      panelClass: 'successSnackBar',
      horizontalPosition: this.HORIZONTAL_SNACKBAR_POSITION,
      verticalPosition: this.VERTICAL_SNACKBAR_POSITION,
    });
  }

  /**
   * The method opens a snackBar with error message and style.
   * The default duration is undefined
   * The default position is bottom-centered
   * @param errorID the error to display
   */
  public openTranslatedErrorSnackbar(errorID: string): void {
    try {

      this.translate.get(errorID).subscribe(
        translation => {
          this.openErrorSnackbar(translation);
        },
        error1 => {
          console.log(error1);
          this.openErrorSnackbar(errorID);
        });
    } catch (error) {
      console.log(error);
      this.openErrorSnackbar(errorID);
    }
  }

  public openInfoSnackbar(msg: string): void {

    this.messageSnackBar.open(msg, 'Chiudi', {
      duration: this.UNDEFINED_SNACKBAR_DURATION,
      panelClass: ['infoSnackBar', 'mat-typography'],
      horizontalPosition: this.HORIZONTAL_SNACKBAR_POSITION,
      verticalPosition: this.VERTICAL_SNACKBAR_POSITION,
    });
  }

  public openErrorSnackbar(error1: string): void {
    console.error(error1);
    this.messageSnackBar.open(error1, 'Chiudi', {
      duration: this.UNDEFINED_SNACKBAR_DURATION,
      panelClass: ['errorSnackBar', 'mat-typography'],
      horizontalPosition: this.HORIZONTAL_SNACKBAR_POSITION,
      verticalPosition: this.VERTICAL_SNACKBAR_POSITION,
    });
  }

  public closeSnackbar(): void {
    this.messageSnackBar.dismiss();
  }


  // *******************************
  //   RESPONSIVE UTILITY METHODS
  // *******************************


  isMobileScreen() {
    return GraphicUtils.isScreenWidthSmaller(this.MOBILE_SCREEN_THRESHOLD);
  }

  isSmallScreen() {
    return GraphicUtils.isScreenWidthSmaller(this.SMALL_SCREEN_THRESHOLD);
  }

  isMediumScreen() {
    return GraphicUtils.isScreenWidthSmaller(this.MEDIUM_SCREEN_THRESHOLD);
  }


  // *******************************
  //     OTHER UTILITY METHODS
  // *******************************

  get EMAIL_PATTERN_VALIDATOR(): string {
    return this._EMAIL_PATTERN_VALIDATOR;
  }

}
