<h2 mat-dialog-title>
  <span>{{'UPDATE_COMPANY.TITLE' | translate }} ({{titleData}})</span>
</h2>
<mat-dialog-content class="mat-typography" style="height: 100px !important;">
  <form #updateCompanyForm="ngForm">
    <div class="rowFlexContent flexWrapContent">
      <!--  ************************************  -->
      <!--           TAX CODE            -->
      <!--  ************************************  -->
      <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
        <mat-label>{{'COMPANY.TABLE.TAX_CODE' | translate }}</mat-label>
        <input matInput class="defaultCursor" #taxCode="ngModel" required type="text" name="taxCode"
               appDecimalNumberFormatter
               [regex]="appService.composeText(16)"
               [(ngModel)]="element.taxCode">
        <mat-error *ngIf="taxCode.invalid">
          {{'COMPANY.TABLE.TAX_CODE_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <!--  ************************************  -->
      <!--                 COMPANY NAME                -->
      <!--  ************************************  -->
      <mat-form-field class="fullWidthField mat-form-field twoThirdsOfWidthFlex">
        <mat-label>{{'COMPANY.TABLE.COMPANY_NAME' | translate }}</mat-label>
        <input matInput class="defaultCursor" #compName="ngModel" required type="text" name="compName"
               appDecimalNumberFormatter
               [regex]="appService.composeText(100)"
               [(ngModel)]="element.companyName">
        <mat-error *ngIf="compName.invalid">
          {{'COMPANY.TABLE.COMPANY_NAME_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

  </form>

</mat-dialog-content>


<!--  *****************************************  -->
<!--    CLOSE/SAVE DIALOG ACTION BUTTON          -->
<!--  *****************************************  -->
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [disabled]="isSavingData" [mat-dialog-close]="null">{{'BUTTONS.CANCEL' | translate }}</button>
  <button mat-raised-button color="primary" [disabled]="isSavingData || updateCompanyForm.invalid"
          (click)="saveCompany()">{{'BUTTONS.SAVE' | translate }}</button>
</mat-dialog-actions>
