import {Injectable} from '@angular/core';
import {BaseOPEService} from "./BaseOPEService";
import {firstValueFrom} from "rxjs";
import {GetCelListCB} from "../../ope/cel/GetCelListCB";
import {GetCelDetailsCB} from "../../ope/cel/GetCelDetailsCB";
import {GetCompanyListCB} from "../../ope/cel/GetCompanyListCB";
import {GetCelCategoryListCB} from "../../ope/cel/GetCelCategoryListCB";
import {PutCelCB} from "../../ope/cel/PutCelCB";
import {CelOPEEnum} from "../../ope/cel/CelOPEEnum";
import {GetAllCompanyListCB} from "../../ope/cel/GetAllCompanyListCB";
import {PutCompanyCB} from "../../ope/cel/PutCompanyCB";
import {PutCelRowCB} from "../../ope/cel/PutCelRowCB";
import {DelCelCB} from "../../ope/cel/DelCelCB";
import {DelCelRowCB} from "../../ope/cel/DelCelRowCB";
import {GetStatisticsCB} from "../../ope/cel/GetStatisticsCB";
import {GetCategoryDetailsCB} from "../../ope/cel/GetCategoryDetailsCB";


@Injectable({
  providedIn: 'root'
})
export class CelOPEService extends BaseOPEService {


  public getCelList(cb: GetCelListCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  // getCelDetails
  public getCelDetails(cb: GetCelDetailsCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  // getCompanyList
  public getCompanyList(cb: GetCompanyListCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  public getAllCompanyList(cb: GetAllCompanyListCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  // putCompany
  public putCompany(cb: PutCompanyCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }
  //
  // // delCompany
  // public delCompany(cb: DelCompanyCB): Promise<any> {
  //   return firstValueFrom(this.execOPE(cb));
  // }

  // getCelCategoryList
  public getCelCategoryList(cb: GetCelCategoryListCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }


  // putCel
  public putCel(cb: PutCelCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  // putCel
  public putCelFile(file: File): Promise<any> {
    return firstValueFrom(this.putFileOPE(CelOPEEnum.PutCelFile, file));
  }

  // putCelRow
  public putCelRow(cb: PutCelRowCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  // delCel
  public delCel(cb: DelCelCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  // delCelRow
  public delCelRow(cb: DelCelRowCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  // getCategoryDetails
  public getCategoryDetails(cb: GetCategoryDetailsCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }

  // getStatistics
  public getStatistics(cb: GetStatisticsCB): Promise<any> {
    return firstValueFrom(this.execOPE(cb));
  }


}
