<h2 mat-dialog-title>
  <span>{{'CEL_CATEGORY_DETAILS.TITLE' | translate : {code: titleData} }}</span>
</h2>
<mat-dialog-content class="mat-typography" style="height: 750px !important;">

  <div *ngIf="!!categoryDetails">

    <div style="display: flex; flex-direction: column; height: 100%; overflow: auto">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows >

      <!-- Cel Num Column -->
      <ng-container matColumnDef="celNum">
        <th mat-header-cell *matHeaderCellDef>{{'CEL.TABLE.CEL_NUM' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.celNum}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Cel Date Column -->
      <ng-container matColumnDef="celDate">
        <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.CEL_DATE' | translate}}  </th>
        <td mat-cell *matCellDef="let element"> {{printDate(element.celDate)}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>


      <!-- Procuring Station Column -->
      <ng-container matColumnDef="procuringStation">
        <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.PROCURING_STATION' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.procuringStation}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Subject Column -->
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.SUBJECT' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.subject}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Cig Column -->
      <ng-container matColumnDef="cig">
        <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.CIG' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.cig}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Start Work Date Column -->
      <ng-container matColumnDef="startWorkDate">
        <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.START_WORK_DATE' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{printDate(element.startWorkDate)}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- End Work Date Column -->
      <ng-container matColumnDef="endWorkDate">
        <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.END_WORK_DATE' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{printDate(element.endWorkDate)}} </td>
        <td mat-footer-cell *matFooterCellDef> {{'CEL_CATEGORY_DETAILS.TOTAL_FOOTER' | translate}}</td>
      </ng-container>

      <!-- Total Amount Column -->
      <ng-container matColumnDef="totalAmountDetail">
        <th mat-header-cell *matHeaderCellDef> {{'CEL_CATEGORY_DETAILS.TOTAL_AMOUNT' | translate : {category: titleData} }} </th>
        <td mat-cell *matCellDef="let element"> {{element.totalAmount | currency : "EUR": "symbol"}} </td>
        <td mat-footer-cell *matFooterCellDef> {{fullTotal | currency : "EUR": "symbol"}} </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetailCel">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="noPadding">
          <div class="example-element-detail celDetailContainer"
               [@detailExpand]="!!selectedRow && element == selectedRow ? 'expanded' : 'collapsed'">

            <div class="celDetailContainer">
              <div class="aFifthOfWidthFlex celDetailRow" *ngFor="let row of element.rows">
                <p class="noMargin"><b>{{row.companyName}}</b></p>
                <p class="noMargin">{{row.taxCode}}</p>
                <p class="noMargin">{{row.amount | currency : "EUR": "symbol"}}</p>
              </div>
            </div>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row [class.example-expanded-row]="selectedRow === row" class="example-element-row"
          [ngClass]="{'highlightRow': !!selectedRow && selectedRow.id === row.id}"
          (click)="setSelectedRow(row)"
          *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

      <tr mat-row *matRowDef="let row; columns: ['expandedDetailCel']" class="example-detail-row"></tr>

      <!--  ************************************  -->
      <!--               NO DATA ROW              -->
      <!--  ************************************  -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell"
            [colSpan]="displayedColumns.length">{{'COMMON.NO_DATA_FOUND' | translate }}</td>
      </tr>
    </table>

    </div>




  </div>


</mat-dialog-content>


<!--  *****************************************  -->
<!--    CLOSE/SAVE DIALOG ACTION BUTTON          -->
<!--  *****************************************  -->
<mat-divider></mat-divider>
<mat-dialog-actions align="end">

  <button mat-raised-button color="primary" (click)="exportData()">{{'BUTTONS.EXPORT' | translate }}</button>
  <button mat-button [mat-dialog-close]="null">{{'BUTTONS.CLOSE' | translate }}</button>
</mat-dialog-actions>
