import {Component, Inject, inject} from '@angular/core';
import {BaseComponent} from "../../BaseComponent";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.css']
})
export class PdfViewerDialogComponent extends BaseComponent {

  private dialog = inject(MatDialogRef<PdfViewerDialogComponent>);
  protected sanitizer: DomSanitizer = inject(DomSanitizer);
  protected http: HttpClient = inject(HttpClient);
  public fileUrl?: SafeResourceUrl;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string)
  {
    super();
    this.dialog.disableClose = true;

    this.tryAndSetFileUrl(data)

  }

  tryAndSetFileUrl(data: string) {
    const theUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);

    this.fileUrl = theUrl;

    // TODO non funziona
    // firstValueFrom(this.http.get(data, {observe: 'response'})).then((resp: any) => {
    //   this.fileUrl = theUrl;
    // }).catch((error) => {
    //   console.log(error);
    //   this.appService.openTranslatedErrorSnackbar('ERROR.FILE_NOT_FOUND');
    //   this.dialog.close();
    // })

  }

}
