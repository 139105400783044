import {AfterViewInit, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../BaseComponent";
import {MenuVoice} from "../../models/user/MenuVoice";
import {MenuGroup} from "../../models/user/MenuGroup";
import {GraphicUtils} from "@aiknow-srl/aik-ngx-common";
import {MatSidenav} from "@angular/material/sidenav";
import {EventNames} from "../../events/EventBusConstants";
import {SpringRestService} from "@aiknow-srl/aik-ngx-http";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {OrderByPipe} from "../../Pipes/orderBy.pipe";
import {Paths} from "../../utils/paths";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

  graphicUtils = GraphicUtils;

  logoutSubscription?: Subscription;
  chargingInfoEvent?: Subscription;

  isChargingInfo = false;

  private route: Router = inject(Router);
  private be: SpringRestService = inject(SpringRestService);
  private orderByPipe: OrderByPipe = inject(OrderByPipe);

  async goto(item: MenuGroup, voice: MenuVoice) {
    this.appService.menuVoice = voice;
    this.appService.menuGroup = item;

    if (!!this.gmService.user.menuItems) {
      this.gmService.user.menuItems.forEach((group) => {

        group.isSelected = group === item;
      });
    }


    try {
      await this.route.navigateByUrl(`${Paths.HOME}/${voice.name}`, {skipLocationChange: true});
    } catch (e) {
      this.logger.error(`Error in goto, trying to go to unknown route [${voice.name}]`, e);
    }

  }

  override ngOnInit(): void {
    super.ngOnInit();
    const defaultGroup = this.defaultMenuGroup();

    if (!!defaultGroup) {
      const defaultVoice = this.defaultMenuVoice(defaultGroup);

      if (!!defaultVoice) {
        this.goto(defaultGroup, defaultVoice);
      } else {
        this.logger.error('No menu for user found...');
      }
    } else {
      this.logger.error('No menu for user found...');
    }

    this.chargingInfoEvent = this.eventBus.on(EventNames.LOADING_INFO, (isCharging: boolean) => {
      this.isChargingInfo = isCharging;
    });

    this.logoutSubscription = this.eventBus.on(EventNames.FORCE_LOGOUT, () => {
      this.be.doLogout();
      localStorage.removeItem('language');
      this.route.navigateByUrl('', {skipLocationChange: true});
    });
  }

  async clickAndClose(item: MenuGroup, voice: MenuVoice, drawer: MatSidenav) {
    await this.goto(item, voice);
    if (GraphicUtils.isScreenWidthGreater(this.appService.MOBILE_SCREEN_THRESHOLD)) {
      await drawer.close();
    }
  }

  ngOnDestroy(): void {
    if (!!this.chargingInfoEvent) {

      this.chargingInfoEvent.unsubscribe();
    }
  }

  defaultMenuGroup() {
    if (!!this.gmService.user.menuItems) {
      const menuItem = this.gmService.user.menuItems[0];

      if (!!menuItem) {
        menuItem.isSelected = true;
        return menuItem;
      }
    }

    return undefined;
  }

  defaultMenuVoice(grp: MenuGroup) {
    if (!!grp.voices) {
      const voice = grp.voices[0];

      if (!!voice) {
        return voice;
      }
    }
    return undefined;
  }

  orderByVoices(voices: MenuVoice[]) {
    return this.orderByPipe.transform(voices, 'orderIndex');
  }

}
