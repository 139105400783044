import {inject} from '@angular/core';
import {BaseSpringServiceCB, SpringRestService} from '@aiknow-srl/aik-ngx-http';
import {Observable} from 'rxjs';
import {GlobalModelService} from "../GlobalModel.service";
import {BaseService} from "../BaseService";
import {AppService} from "../App.service";

export abstract class BaseOPEService extends BaseService {

  // ******************************************************
  //                 Dependency Injection
  // ******************************************************
  private be = inject(SpringRestService);
  private gmService = inject(GlobalModelService);
  private appService = inject(AppService);

  protected execOPE(cb: BaseSpringServiceCB): Observable<any> {
    return this.be.execOPE(this.appService.composeUrl(), cb);
  }

  protected putFileOPE(fileOpe: string, file: File, params: Map<string, string> = new Map<string, string>()): Observable<any> {
    return this.be.putImage(this.appService.composePutImageUrl(fileOpe), file, params);
  }

}
