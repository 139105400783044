import {
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {BaseComponent} from "../../BaseComponent";
import {GetStatisticsCB} from "../../../ope/cel/GetStatisticsCB";
import {CelOPEService} from "../../../Services/ope/CelOPE.service";
import {CelCategoryValue} from "../../../models/cel/CelCategoryValue";
// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {CurrencyPipe} from "@angular/common";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {GetCategoryDetailsCB} from "../../../ope/cel/GetCategoryDetailsCB";
import {CelCategoryDetails} from "../../../models/cel/CelCategoryDetails";
import {NgForm} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-dashboard',
  templateUrl: './cel-dashboard.component.html',
  styleUrls: ['./cel-dashboard.component.css']
})
export class CelDashboardComponent extends BaseComponent implements OnInit, OnDestroy {

  soaCalc = false;
  startDate?: Date | null;
  endDate?: Date | null;

  statistics?: CelCategoryValue[];

  // categoryDetails?: CelCategoryDetails[];

  private root!: am5.Root;

  celOPEService = inject(CelOPEService);
  currencyPipe = inject(CurrencyPipe);
  public dialog = inject(MatDialog);

  /**
   * Material Toggle Position
   */
  readonly LABEL_POSITION = 'after';

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone) {
    super();
  }


  override ngOnInit() {
    super.ngOnInit();

    this.getCelStatistics();

  }


  getCelStatistics() {
    const cb = new GetStatisticsCB();
    cb.i.considerAlsoSubContractors = this.soaCalc;
    cb.i.startDate = this.startDate?.getTime();
    cb.i.endDate = this.endDate?.getTime();

    this.celOPEService.getStatistics(cb).then((_) => {

      this.statistics = cb.o.categories.sort((a, b) => a.id - b.id);

      this.statistics.forEach(a => {
        const res = this.currencyPipe.transform(a.totalAmount, "EUR", "symbol");
        a.totalAmountAsText = (!!res) ? res : '';
      })


      this.drawChart();


    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });
  }

  drawChart() {
    // Chart code goes in here
    this.appService.browserOnly(this.platformId, this.zone, () => {

      if (this.root) {
        this.root.dispose();
      }

      if (!!this.statistics) {
        this.root = am5.Root.new("statisticsChart");

        this.root.setThemes([am5themes_Animated.new(this.root)]);

        this.root._logo?.dispose();

        let chart = this.root.container.children.push(
          am5percent.PieChart.new(this.root, {
            endAngle: 270,
            layout: this.root.verticalLayout,
            innerRadius: am5.percent(60)
          })
        );

        // Define data
        let series = chart.series.push(
          am5percent.PieSeries.new(this.root, {
            name: "Statistics",
            categoryField: "categoryCode",
            valueField: "totalAmount",
            endAngle: 270,
            alignLabels: false,
            legendLabelText: "[{fill}]{categoryCode}[/]",
            legendValueText: "{totalAmountAsText}"
          })
        );

        series.children.push(am5.Label.new(this.root, {
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          fontSize: "1.5em"
        }));


        series.slices.template.setAll({
          fillPattern: am5.GrainPattern.new(this.root, {
            maxOpacity: 0.2,
            density: 0.5,
            colors: [am5.color(0xffffff)]
          }),
          cornerRadius: 10,
          strokeWidth: 2,
          shadowOpacity: 0.1,
          shadowOffsetX: 2,
          shadowOffsetY: 2,
          shadowColor: am5.color(0x000000),
          stroke: am5.color(0xffffff),
          toggleKey: "none",
          tooltipText: "[bold]{categoryCode}[/]\n{totalAmountAsText}"
        });

        series.slices.template.events.on("click", (ev) => {
          console.log("Clicked on slice", ev.target.dataItem?.dataContext);

          const categoryValue = ev.target.dataItem?.dataContext as CelCategoryValue;
          if (!!categoryValue) {
            this.getCelCategoryDetails(categoryValue);
          }

        });

        series.states.create("hidden", {
          endAngle: -90
        });

        series.labels.template.setAll({
          textType: "circular",
          fontSize: 20,
          text: "[bold]{categoryCode}[/]\n{valuePercentTotal.formatNumber('0.00')}%"
        });


        // TODO
        // https://www.amcharts.com/docs/v4/tutorials/chart-legend-in-an-external-container/

        // @ts-ignore
        series.labels.template.adapters.add("y", (y, target) => {
          let dataItem = target.dataItem;
          if (dataItem) {
            // @ts-ignore
            var tick = dataItem.get("tick") as any;
            if (tick) {
              // @ts-ignore
              if ((dataItem.get("valuePercentTotal") as any) < 1) {
                target.set("forceHidden", true);
                tick.set("forceHidden", true);
              }
              else {
                target.set("forceHidden", false);
                tick.set("forceHidden", false);
              }
            }
            return y;
          }
        });

        series.data.setAll(this.statistics);

        let legend = chart.children.push(am5.Legend.new(this.root, {
          centerY: am5.percent(100),
          x: am5.percent(50),
          centerX: am5.percent(50),

          layout: this.root.gridLayout

        }));

        legend.data.setAll(series.dataItems);

        series.appear(1000, 100);
      }

    });
  }

  getCelCategoryDetails(elem: CelCategoryValue) {

    const cb = new GetCategoryDetailsCB();
    cb.i.categoryId = elem.id;
    cb.i.startDate = this.startDate?.getTime();
    cb.i.endDate = this.endDate?.getTime();
    cb.i.considerAlsoSubContractors = this.soaCalc;

    this.celOPEService.getCategoryDetails(cb).then((_) => {

      // this.categoryDetails = cb.o.cels;

      this.appService.browserOnly(this.platformId, this.zone, () => {
        const dialogRef = this.dialog.open(CelCategoryDetailsDialogComponent, {
          width: '80vw',
          data: {
            categoryDetails: cb.o.cels,
            categoryCode: elem.categoryCode
          }
        });
      });

    }).catch((error) => {
      this.appService.openErrorSnackbar(error);
    });

  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.appService.browserOnly(this.platformId, this.zone,() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  assignStartDate(startDate: MatDatepickerInputEvent<Date, any | null>) {
    this.startDate = startDate.value;
  }

  assignEndDate(endDate: MatDatepickerInputEvent<Date, any | null>) {
    this.endDate = endDate.value;
  }
}



/*----------------------------------------------
   CEL CATEGORY DETAILS DIALOG Component
 ---------------------------------------------*/

@Component({
  templateUrl: 'cel-category-details-dialog.html',
  selector: 'app-cel-category-details-dialog',
  styleUrls: ['./cel-dashboard.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CelCategoryDetailsDialogComponent extends BaseComponent implements OnInit {

  isSavingData = false;
  categoryDetails: CelCategoryDetails[];
  dataSource!: MatTableDataSource<CelCategoryDetails>;
  selectedRow?: CelCategoryDetails;

  fullTotal = 0;
  displayedColumns: string[] = ['celNum', 'celDate', 'procuringStation', 'subject', 'cig','startWorkDate', 'endWorkDate', 'totalAmountDetail'];

  titleData: string = '';

  @ViewChild('updateCompanyForm') updateCompanyForm!: NgForm;

  private dialog = inject(MatDialogRef<CelCategoryDetailsDialogComponent>);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, @Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone)
  {
    super();
    this.dialog.disableClose = true;
    this.dataSource = new MatTableDataSource<CelCategoryDetails>(data.categoryDetails);

    this.fullTotal = data.categoryDetails.reduce((acc: number, val: CelCategoryDetails) => acc + val.totalAmount, 0);

    this.categoryDetails = data.categoryDetails;

    this.titleData = data.categoryCode;

  }


  printDate(date: any): string {
    if (!!date) {
      const theDate = new Date(date);
      return this.appService.printDateTimeInItalianFormat(theDate, false);
    }
    return '-';
  }

  setSelectedRow(row: CelCategoryDetails) {
    if (this.selectedRow?.id === row.id) {
      this.selectedRow = undefined;
    } else {
      this.selectedRow = row;
    }
  }

  exportData() {

    let header = "sep=;\r\n";
    header += "Numero CEL;Data CEL;Stazione appaltante;Oggetto;CIG;Data inizio lavori;Data fine lavori;Importo totale";

    let csvContent = header + '\r\n';

    let amountSum = 0;

    this.categoryDetails.forEach((row) => {
      csvContent += row.celNum + ";" + this.printDate(row.celDate) + ";" + row.procuringStation + ";" + row.subject + ";" + row.cig + ";" + this.printDate(row.startWorkDate) + ";" + this.printDate(row.endWorkDate) + ";" + row.totalAmount + "\r\n";
      amountSum += row.totalAmount;
    });
    csvContent += '\r\n';
    csvContent += ";;;;;;" + "Totale;" + amountSum.toFixed(2);

    const fileName = `CEL_Cat_${this.titleData}_${new Date().toLocaleString()}.csv`;
    const blob = new Blob([csvContent], {type: 'text/csv'});
    saveAs(blob, fileName);
  }
}
