import {Inject, Injectable, LOCALE_ID, PLATFORM_ID} from '@angular/core';
import {MatDateFormats, NativeDateAdapter} from '@angular/material/core';
import {Platform} from "@angular/cdk/platform";
import {getLocaleFirstDayOfWeek} from "@angular/common";

/**
 * This class is used to parse and format a date in a custom format
 * @example dd/mm/yyyy in this app
 */
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {

  constructor(
    @Inject(LOCALE_ID) public override locale: string,
    @Inject(PLATFORM_ID) platformId: Platform
  ) {
    super(locale, platformId);
    console.log(locale);
  }

  override getFirstDayOfWeek(): number {
    // return 1;
    return getLocaleFirstDayOfWeek(this.locale);
  }

  /**
   * This method casts a numeric string into an integer
   * @param value numeric string to cast
   */
  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  /**
   * This method chechs whether a numeric string is a number or not
   * @param value the numeric string
   */
  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  /**
   * This method pads a number with 0
   * @param value number
   */
  padNumber(value: number): string {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

  /**
   * This method is used to format a date using a custom format
   * @example dd/mm/yyyy
   * @param date to format
   */
  override format(date: Date): string {

    const day = this.isNumber(date.getDate()) ? this.padNumber(date.getDate()) : '';
    const month = this.isNumber(date.getMonth() + 1) ? this.padNumber(date.getMonth() + 1) : '';

    return date ? `${day}/${month}/${date.getFullYear()}` : '';
  }


  /**
   * Method used to parse a date from a string formatted as DD/MM/YYYY or a number
   * @param value The date as a string
   */
  override parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'numeric'},
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};
