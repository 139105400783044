import {inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import Bugsnag from "@bugsnag/js";
import {TranslateService} from "@ngx-translate/core";
import {SpringRestService} from "@aiknow-srl/aik-ngx-http";
import {PostLoginUser} from "../models/user/PostLoginUser";
import {PostLoginCB} from "../ope/user/PostLoginCB";
import {Locale} from "../models/user/Locale";

@Injectable({
  providedIn: 'root'
})
export class GlobalModelService {

  protected translate = inject(TranslateService);
  private be: SpringRestService = inject(SpringRestService);

  selectedItem?: string;

  // ----------------------------------
  //   POST LOGIN PARAMS
  // ----------------------------------
  /**
   * Application Id
   */
  private _appId?: string;
  /**
   * Environment type
   */
  private _envId?: string;
  /**
   * Application version
   */
  private _appVersion?: string;
  /**
   * Environment Label type
   */
  private _envIdLabel?: string;
  /**
   * Logged User
   */
  private _user?: PostLoginUser;
  /**
   * Languages list
   */
  private _localeList?: Locale[];


  // ***************************************
  //      PostLogin Getters and Setters
  // ***************************************
  /**
   * Getter for _appId
   */
  get appId(): string {
    // @ts-ignore
    return this._appId;
  }

  /**
   * Setter for _appId
   */
  set appId(value: string) {
    this._appId = value;
  }

  /**
   * Getter for _envId
   */
  get envId(): string {
    // @ts-ignore
    return this._envId;
  }

  /**
   * Setter for _envId
   */
  set envId(value: string) {
    this._envId = value;
  }

  /**
   * Getter for _appVersion
   */
  get appVersion(): string {
    // @ts-ignore
    return this._appVersion;
  }

  /**
   * Setter for _appVersion
   */
  set appVersion(value: string) {
    this._appVersion = value;
  }

  /**
   * Getter for _envIdlabel
   */
  get envIdLabel(): string {
    // @ts-ignore
    return this._envIdLabel;
  }

  /**
   * Setter for _envIdlabel
   */
  set envIdLabel(value: string) {
    this._envIdLabel = value;
  }

  /**
   * Getter for _user
   */
  get user(): PostLoginUser {

    // @ts-ignore
    return this._user;
  }

  /**
   * Setter for _user
   */
  set user(value: PostLoginUser) {
    this._user = value;
  }

  /**
   * Getter for _localeList
   */
  get localeList(): Locale[] {
    // @ts-ignore
    return this._localeList;
  }

  /**
   * Setter for _localeList
   */
  set localeList(value: Locale[]) {
    this._localeList = value;
  }


  /**
   * Tokenizes the appVersion returning only the first part
   */
  getAppVersion(): string {
    // Version is in the form x.y.z_buildData
    // Returning only x.y.z
    if (this.appVersion !== undefined) {
      return this.appVersion.split('_')[0];
    } else {
      return '';
    }

  }

  /**
   * The method is used to store in the Global Model Service all the params in the postLogin service response
   * @param cb the GetTransportItemDetailCB object
   */
  public storeParamsIntoGlobalModel(cb: PostLoginCB): void {
    this.appId = cb.o.appId;
    this.envId = cb.o.envId;
    this.appVersion = cb.o.appVersion;
    this.envIdLabel = cb.o.envIdLabel;

    this.user = new PostLoginUser();

    if (!!cb.o.user){

      this.user.id = cb.o.user.id;
      this.user.username = cb.o.user.username;
      this.user.firstname = cb.o.user.firstname;
      this.user.lastname = cb.o.user.lastname;
      this.user.language = cb.o.user.language;
      this.user.roles = cb.o.user.roles;
      this.user.menuItems = cb.o.user.menuItems;


    }

    this.localeList = cb.o.localeList.sort((a, b) => a.locale.localeCompare(b.locale));

    this.setCountryFlag();

    if (!!this.user.language && this.isValidLanguage(this.user.language)) {
      this.translate.use(this.user.language);
      this.be.setLanguage(this.user.language);
      localStorage.setItem('language', this.user.language);
    }

    if (environment.production) {
      // ADD USER FOR BUGSNAG REPORTS
      Bugsnag.addOnError(event => event.setUser(this.user.id.toString(), this.user.username, `${this.user.firstname} ${this.user.lastname}`));
      Bugsnag.addOnError(event => event.addMetadata('APP', {version: this.appVersion}));
    }

  }

  /**
   * Set country flag basing on locale
   */
  private setCountryFlag(): void {
    for (const locale of this.localeList) {
      if (locale.locale === 'it') {
        locale.countryFlag = 'it';
      } else if (locale.locale === 'en') {
        locale.countryFlag = 'gb';
      } else if (locale.locale === 'de') {
        locale.countryFlag = 'de';
      } else if (locale.locale === 'es') {
        locale.countryFlag = 'es';
      } else if (locale.locale === 'fr') {
        locale.countryFlag = 'fr';
      }
    }
  }

  private isValidLanguage(lang: string | undefined) {
    switch (lang) {
      case 'it':
      case 'en':
      case 'de':
      case 'es':
      case 'fr':
        return true;
      default:
        return false;
    }
  }

  public userHasAdminRole(): boolean {

    const flag = this.user.roles?.includes('R_ADMIN');

    if (flag) {
      return flag;
    }
    return false;
  }


  public userHasFullUserRole(): boolean {

    const flag = this.user.roles?.includes('R_USER_FULL');

    if (flag) {
      return flag;
    }
    return false;
  }

}
