import {CelOPEEnum} from "./CelOPEEnum";
import {BaseSpringServiceCB} from "@aiknow-srl/aik-ngx-http";


/**
 * I inner class
 */
class I {
  id?: number;
  celDate!: number;
  celNum!: string;
  procuringStation!: string;
  subject!: string;
  cig?: string;
  startWorkDate!: number;
  endWorkDate!: number;
  accountedAmount!: number;
  fileName!: string;

}

/**
 * O inner class
 */

class O {
  id!: number;
}

/**
 * put reseller class
 */

export class PutCelCB extends BaseSpringServiceCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(CelOPEEnum.PutCel);
    this.i = new I();
    this.o = new O();
  }

}
