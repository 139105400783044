import {CelOPEEnum} from "./CelOPEEnum";
import {BasePageI, BasePageO, PaginatedBaseCB} from "../PaginatedBaseCB";


/**
 * I inner class
 */
class I extends BasePageI {
}

/**
 * O inner class
 */

class O extends BasePageO {
  companies!: CompanyDTO[];
}

class CompanyDTO {
  id!: number;
  taxCode!: string;
  companyName!: string;
  isMainCompany!: boolean;
}


/**
 * put reseller class
 */

export class GetCompanyListCB extends PaginatedBaseCB {

  /**
   * I
   */
  public override i: I;


  /**
   * O
   */
  public override o: O;


  /**
   * constructor
   */
  constructor() {
    super(CelOPEEnum.GetCompanyList);
    this.i = new I();
    this.o = new O();
  }

}
