<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer mode="side" class="sidenav" [fixedInViewport]=true
               [attr.role]="graphicUtils.isScreenWidthGreater(appService.SMALL_SCREEN_THRESHOLD) ? 'dialog' : 'navigation'"
               [mode]="graphicUtils.isScreenWidthGreater(appService.SMALL_SCREEN_THRESHOLD) ? 'over' : 'over'"
               [opened]="false">
    <div class="sidenavContent sidenavLogoContainer">
      <img alt="logoSidenav" class="logoSidenav" src="assets/mdr25.png"/>
    </div>
    <div *ngFor="let item of gmService.user.menuItems | orderBy: 'orderIndex'" (click)="item.isSelected = !item.isSelected;"
         [class]="item.id === appService.menuGroup?.id ? 'sidenav-grp grp-selected' : 'sidenav-grp'">

      <div class="sidenavContent"
           [class]="item.id === appService.menuGroup?.id ? 'sidenavContent item-selected' : 'sidenavContent'">
        <p class="noMargin">{{('MENU_GRP.' + item.name | translate).toUpperCase()}}</p>
        <span class="mat-icon material-symbols-outlined">{{(item.isSelected) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</span>
      </div>

      <div *ngIf="item.id === appService.menuGroup?.id || item.isSelected">
        <div *ngFor="let voice of item.voices | orderBy: 'orderIndex'"
             (click)="clickAndClose(item, voice, drawer)"
             [class]="voice.id === appService.menuVoice?.id ? 'sidenav-item voice-selected' : 'sidenav-item'">

          <div [class]="voice.id === appService.menuVoice?.id ? 'voice-container-selected' : ''">
            <p [class]="voice.id === appService.menuVoice?.id ? 'sidenavVoiceContentSelected' : 'sidenavVoiceContent'">
              {{'MENU_VOICE.' + item.name + '.' + voice.name.toUpperCase() | translate}}
            </p>
          </div>


        </div>
      </div>

    </div>

  </mat-sidenav>
  <mat-sidenav-content class="sidenavMainContent" [style.opacity]="isChargingInfo ? '0.5' : '1' ">
    <app-topbar [componentName]="appService.menuGroup?.id" [drawer]="drawer"></app-topbar>
    <div class="page-container">
      <router-outlet></router-outlet>


    </div>

  </mat-sidenav-content>
</mat-sidenav-container>

<div id="tb-loading-spinner" style="z-index: 100 !important;" *ngIf="isChargingInfo" class="tb-loading-spinner">
  <div class="tb-bounce1"></div>
  <div class="tb-bounce2"></div>
  <div class="tb-bounce3"></div>
</div>
