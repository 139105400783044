import {CelCategory} from "./CelCategory";
import {CelCompany} from "./CelCompany";

export class CelRow {
  id!: number;
  category?: CelCategory;
  company?: CelCompany;
  amount!: number;
  isSubcontractor: boolean = false;
  fosterCompany?: CelCompany;
}
