<mat-toolbar class="toolbar">
  <!--  *************************** -->
  <!--        HAMBURGER BUTTON      -->
  <!--  *************************** -->
  <button
    type="button"
    mat-icon-button
    (click)="drawer.toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <span class="component-title" *ngIf="!!appService.menuVoice">
    {{'MENU_VOICE.' + appService.menuGroup?.name + '.' + appService.menuVoice.name.toUpperCase() | translate}}
  </span>

  <span class="example-spacer"></span>


  <!--  *************************** -->
  <!--          APP VERSION         -->
  <!--  *************************** -->
  <span class="envText" *ngIf="!isSmallScreen">Ver. {{gmService.getAppVersion()}}</span>

  <!--  *************************** -->
  <!--          ENVIRONMENTS        -->
  <!--  *************************** -->
  <span class="envText" *ngIf="env !== 'prod' && !isSmallScreen">{{printEnvironments()}}</span>


  <!--  *************************** -->
  <!--             ACCOUNT          -->
  <!--  *************************** -->
  <span class="user-chip"  *ngIf="!isSmallScreen"
        [matTooltip]="'User menu'"
        [matTooltipClass]="'multiline-tooltip'"
        [matTooltipPosition]="'below'">
    <button mat-menu-item class="customMatMenuItem noPadding" (click)="openChangePassword()">

      <mat-icon class="customIcon">account_circle</mat-icon>
      {{gmService.user.firstname}} {{gmService.user.lastname}}
  </button>
  </span>
  <button mat-icon-button title="{{gmService.user.username}}" (click)="openChangePassword()" *ngIf="isSmallScreen">
    <mat-icon>account_circle</mat-icon>
  </button>


  <!--  *************************  -->
  <!--        DROPDOWN MENU        -->
  <!--  *************************  -->
  <button mat-icon-button [matMenuTriggerFor]="dropMenu" >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #dropMenu="matMenu">
    <p mat-menu-item *ngIf="env !== 'prod' && isSmallScreen" [disableRipple]="true" class="menuItemElement">{{printEnvironments()}}</p>
    <button mat-menu-item *ngIf="isUnloadedComponent() && isSmallScreen" (click)="refreshElements()">
      <mat-icon>refresh</mat-icon>Aggiorna
    </button>
    <button mat-menu-item title="{{gmService.user.firstname}} {{gmService.user.lastname}} menu" (click)="openChangePassword()" *ngIf="isSmallScreen">
      <mat-icon>account_circle</mat-icon>{{gmService.user.firstname}} {{gmService.user.lastname}}
    </button>
    <button mat-menu-item aria-label="Logout" (click)="logout()">
      <mat-icon>logout</mat-icon>Logout
    </button>
  </mat-menu>


</mat-toolbar>
