<h2 mat-dialog-title>
  <span *ngIf="!isUpdate">{{'UPSERT_CEL.TITLE_NEW' | translate }}</span>
  <span *ngIf="isUpdate">{{'UPSERT_CEL.TITLE_UPDATE' | translate }} ({{element.celNum}})</span>
</h2>
<mat-dialog-content class="mat-typography" style="height: 750px !important;">


  <mat-tab-group [selectedIndex]="selTab" class="fullHeight100"
                 (selectedIndexChange)="selTab = $event">
    <mat-tab class="fullHeight100" [tabIndex]="CEL_TAB" label="{{'UPSERT_CEL.CEL_DATA' | translate}}">

      <form #upsertCel="ngForm">
        <div *ngIf="!!element" style="margin-top: 20px;" class="rowFlexContent flexWrapContent">

          <!--  ************************************  -->
          <!--               CEL_NUMBER               -->
          <!--  ************************************  -->
          <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
            <mat-label>{{'UPSERT_CEL.CEL_NUMBER' | translate }}</mat-label>
            <input matInput class="defaultCursor" #celNum="ngModel" required type="text" name="celNum"
                   appDecimalNumberFormatter
                   [regex]="appService.composeText(15)"
                   [(ngModel)]="element.celNum">
            <mat-error *ngIf="celNum.invalid">
              {{'UPSERT_CEL.CEL_NUMBER_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>

          <!--  ************************************  -->
          <!--                 CEL_DATE               -->
          <!--  ************************************  -->
          <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
            <mat-label>{{'UPSERT_CEL.CEL_DATE' | translate }}</mat-label>
            <input matInput [matDatepicker]="dp_deliveryNoteDate" required (dateChange)="assignCelDate($event)"
                   [value]="celDate">
            <mat-datepicker-toggle matSuffix [for]="dp_deliveryNoteDate"></mat-datepicker-toggle>
            <mat-datepicker #dp_deliveryNoteDate></mat-datepicker>
          </mat-form-field>

          <!--  ************************************  -->
          <!--                   CIG                  -->
          <!--  ************************************  -->
          <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
            <mat-label>{{'UPSERT_CEL.CIG' | translate }}</mat-label>
            <input matInput class="defaultCursor" type="text" name="cig"
                   appDecimalNumberFormatter
                   [regex]="appService.composeText(15)"
                   [(ngModel)]="element.cig">
          </mat-form-field>

          <!--  ************************************  -->
          <!--           PROCURING_STATION            -->
          <!--  ************************************  -->
          <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
            <mat-label>{{'UPSERT_CEL.PROCURING_STATION' | translate }}</mat-label>
            <input matInput class="defaultCursor" #procuringStation="ngModel" required type="text"
                   name="procuringStation"
                   appDecimalNumberFormatter
                   [regex]="appService.composeText(100)"
                   [(ngModel)]="element.procuringStation">
            <mat-error *ngIf="procuringStation.invalid">
              {{'UPSERT_CEL.PROCURING_STATION_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>

          <!--  ************************************  -->
          <!--                 SUBJECT                -->
          <!--  ************************************  -->
          <mat-form-field class="fullWidthField mat-form-field twoThirdsOfWidthFlex">
            <mat-label>{{'UPSERT_CEL.SUBJECT' | translate }}</mat-label>
            <input matInput class="defaultCursor" #subject="ngModel" required type="text" name="subject"
                   appDecimalNumberFormatter
                   [regex]="appService.composeText(500)"
                   [(ngModel)]="element.subject">
            <mat-error *ngIf="subject.invalid">
              {{'UPSERT_CEL.SUBJECT_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>


          <!--  ************************************  -->
          <!--                  AMOUNT                -->
          <!--  ************************************  -->
          <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
            <mat-label>{{'UPSERT_CEL.AMOUNT' | translate }}</mat-label>
            <input matInput class="defaultCursor" #accountedAmount="ngModel" required type="number"
                   name="accountedAmount"
                   [(ngModel)]="element.accountedAmount">
            <mat-error *ngIf="accountedAmount.invalid">
              {{'UPSERT_CEL.AMOUNT_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>

          <!--  ************************************  -->
          <!--              CEL_START_DATE            -->
          <!--  ************************************  -->
          <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
            <mat-label>{{'UPSERT_CEL.CEL_START_DATE' | translate }}</mat-label>
            <input matInput [matDatepicker]="db_startDate" required (dateChange)="assignCelStartDate($event)"
                   [value]="celStartDate">
            <mat-datepicker-toggle matSuffix [for]="db_startDate"></mat-datepicker-toggle>
            <mat-datepicker #db_startDate></mat-datepicker>

          </mat-form-field>

          <!--  ************************************  -->
          <!--                CEL_END_DATE            -->
          <!--  ************************************  -->
          <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
            <mat-label>{{'UPSERT_CEL.CEL_END_DATE' | translate }}</mat-label>
            <input matInput [matDatepicker]="dp_endDate" required (dateChange)="assignCelEndDate($event)" [value]="celEndDate">
            <mat-datepicker-toggle matSuffix [for]="dp_endDate"></mat-datepicker-toggle>
            <mat-datepicker #dp_endDate></mat-datepicker>
          </mat-form-field>


          <div>
            <input #pdfInput hidden type="file" onclick="this.value=null" (change)="celFileOk($event.target)"
                   accept=".pdf"/>
            <button mat-flat-button color="primary"
                    (click)="pdfInput.click()">{{'UPSERT_CEL.CEL_FILE' | translate }}</button>
            <span style="margin-left: 20px" *ngIf="fileIsPresent()">{{printCelFileName()}}</span>
          </div>


        </div>

      </form>

    </mat-tab>


    <mat-tab class="fullHeight100" [tabIndex]="CEL_ROWS_TAB" [disabled]="!element.id" label="{{'UPSERT_CEL.CEL_ROWS_DATA' | translate }}">
      <div *ngIf="!!element" style="margin-top: 20px;" class="rowFlexContent flexWrapContent">

        <!--  ************************************  -->
        <!--               CATEGORY               -->
        <!--  ************************************  -->
        <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
          <mat-label>{{'UPSERT_CEL_ROW.CATEGORY' | translate }}</mat-label>


          <mat-select required #cats="ngModel" name="categories" [(ngModel)]="newCelRow.category">
            <mat-option *ngFor="let category of celCategories" [value]="category">
              {{category.code}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="cats.invalid">
            {{'UPSERT_CEL_ROW.CATEGORY_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <!--  ************************************  -->
        <!--                 COMPANY                -->
        <!--  ************************************  -->
        <mat-form-field class="fullWidthField mat-form-field twoThirdsOfWidthFlex">
          <mat-label>{{'UPSERT_CEL_ROW.COMPANY' | translate }}</mat-label>

          <input type="text" required name="companies" [value]="printCompanyName()"
                 placeholder="Seleziona azienda"
                 matInput
                 #companyInput
                 (keyup)="filterCompanies($event)"
                 name="selectedCompany"
                 [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option [value]="undefined" (click)="newCelRow.company = undefined">
              Seleziona azienda
            </mat-option>
            <mat-option *ngFor="let company of filteredCompanies" [value]="company" (click)="setCompany(company)">
              <b>{{company.companyName}}</b> [{{company.taxCode}}]
            </mat-option>
          </mat-autocomplete>

          <!--            <mat-select required #comps="ngModel" name="companies" [(ngModel)]="newCelRow.company">-->
          <!--              <mat-option *ngFor="let company of companies" [value]="company">-->
          <!--                <b>{{company.companyName}}</b> [{{company.taxCode}}]-->
          <!--              </mat-option>-->
          <!--            </mat-select>-->
          <mat-error *ngIf="companyInput.value != undefined">
            {{'UPSERT_CEL_ROW.COMPANY_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <!--  ************************************  -->
        <!--                 AMOUNT                 -->
        <!--  ************************************  -->
        <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
          <mat-label>{{'UPSERT_CEL_ROW.AMOUNT' | translate }}</mat-label>
          <input matInput #amountRow="ngModel" class="defaultCursor" required type="number" name="amount" [(ngModel)]="newCelRow.amount">

          <mat-error *ngIf="amountRow.invalid">
            {{'UPSERT_CEL_ROW.AMOUNT_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <!-- ******************************* -->
        <!--         REMEMBER ME FLAG        -->
        <!-- ******************************* -->
        <mat-slide-toggle color="primary"
                          [labelPosition]="LABEL_POSITION"
                          [(ngModel)]="newCelRow.isSubcontractor"
                          [disabled]="!!newCelRow.company && newCelRow.company.isMainCompany"
                          (ngModelChange)="toggleSubcontractor($event)"
                          name="rememberMeToggle"
                          class="toggleMargins">
          {{'UPSERT_CEL_ROW.SUBCONTRACTOR' | translate }}
        </mat-slide-toggle>

        <div style="flex: 1 1"></div>

        <div style="margin-right: 20px">
          <button mat-raised-button color="primary"
                  [disabled]="isSavingData || !checkCelRowIsOk(this.newCelRow)"
                  (click)="addCelRow()">{{'BUTTONS.ADD' | translate }}</button>
        </div>

        <mat-accordion class="fullWidthFlex">

          <mat-expansion-panel [disabled]="!!newCelRow.company" (opened)="addCompanyState = true; createNewCompany()"
                               (closed)="addCompanyState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'COMPANY.TABLE.ADD' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <form #addCompanyForm="ngForm">
              <div class="rowFlexContent flexWrapContent">
                <!--  ************************************  -->
                <!--           TAX CODE            -->
                <!--  ************************************  -->
                <mat-form-field class="fullWidthField mat-form-field aThirdOfWidthFlex">
                  <mat-label>{{'COMPANY.TABLE.TAX_CODE' | translate }}</mat-label>
                  <input matInput class="defaultCursor" #taxCode="ngModel" required type="text" name="taxCode"
                         appDecimalNumberFormatter
                         [regex]="appService.composeText(16)"
                         [(ngModel)]="newCompany.taxCode">
                  <mat-error *ngIf="taxCode.invalid">
                    {{'COMPANY.TABLE.TAX_CODE_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>

                <!--  ************************************  -->
                <!--                 COMPANY NAME                -->
                <!--  ************************************  -->
                <mat-form-field class="fullWidthField mat-form-field twoThirdsOfWidthFlex">
                  <mat-label>{{'COMPANY.TABLE.COMPANY_NAME' | translate }}</mat-label>
                  <input matInput class="defaultCursor" #compName="ngModel" required type="text" name="compName"
                         appDecimalNumberFormatter
                         [regex]="appService.composeText(100)"
                         [(ngModel)]="newCompany.companyName">
                  <mat-error *ngIf="compName.invalid">
                    {{'COMPANY.TABLE.COMPANY_NAME_FIELD' | translate }} {{'COMMON.IS_REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>

            </form>
            <div class="rowFlexContent" style="justify-content: flex-end;">
              <button mat-raised-button color="primary"
                      [disabled]="isSavingData || addCompanyForm.invalid"
                      (click)="addCompany()">{{'BUTTONS.ADD' | translate }}</button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>


      </div>

      <div class="innerTableContainer celRowsTableInDialog">
        <table mat-table [dataSource]="celRowsDataSource" aria-describedby="stdTable">

          <!-- CATEGORY Column -->
          <ng-container matColumnDef="rowCategory">
            <th mat-header-cell *matHeaderCellDef> {{'CEL_ROW.TABLE.CATEGORY' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.category.code}}</td>
          </ng-container>

          <!-- COMPANY Column -->
          <ng-container matColumnDef="company">
            <th mat-header-cell
                *matHeaderCellDef> {{'CEL_ROW.TABLE.COMPANY' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.company.companyName}}</td>
          </ng-container>

          <!-- FOSTER_COMPANY Column -->
          <ng-container matColumnDef="fosterCompany">
            <th mat-header-cell
                *matHeaderCellDef> {{'CEL_ROW.TABLE.FOSTER_COMPANY' | translate}} </th>
            <td mat-cell
                *matCellDef="let element">{{(!!element.fosterCompany) ? element.fosterCompany.companyName : ''}}</td>
          </ng-container>

          <!-- AMOUNT Column -->
          <ng-container matColumnDef="rowAmount">
            <th mat-header-cell
                *matHeaderCellDef> {{'CEL_ROW.TABLE.AMOUNT' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.amount  | currency : "EUR": "symbol" }}</td>
          </ng-container>

          <!-- AMOUNT Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell
                *matHeaderCellDef> {{'CEL_ROW.TABLE.DELETE' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" [title]="'CEL_ROW.TABLE.DELETE' | translate"
                      (click)="removeRow(element); $event.stopPropagation();">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="celRowColumns"></tr>
          <tr mat-row
              *matRowDef="let row; columns: celRowColumns;"></tr>

          <!--  ************************************  -->
          <!--               NO DATA ROW              -->
          <!--  ************************************  -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell"
                [colSpan]="celRowColumns.length">{{'COMMON.NO_DATA_FOUND' | translate }}</td>
          </tr>
        </table>
      </div>


    </mat-tab>
  </mat-tab-group>


</mat-dialog-content>


<!--  *****************************************  -->
<!--    CLOSE/SAVE DIALOG ACTION BUTTON          -->
<!--  *****************************************  -->
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [disabled]="isSavingData" [mat-dialog-close]="null">{{'BUTTONS.CLOSE' | translate }}</button>
  <button mat-raised-button color="primary" *ngIf="this.selTab === this.CEL_TAB"
          [disabled]="isSavingData || upsertCel.invalid || !celStartDateIsOk || !celEndDateIsOk || !fileIsPresent()"
          (click)="saveCel()">{{'BUTTONS.SAVE_PROCEED' | translate }}</button>
</mat-dialog-actions>
