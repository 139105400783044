<div class="fullPageContainer">
  <div class="fillPage" style="display: flex; flex-direction: column" *ngIf="canRead()">

    <div [ngClass]="(isSmallScreen) ? 'rowFlexContent' : 'columnFlexContent'" fxLayoutGap="10px grid"
         fxLayoutGap.lt-lg="0 grid"
         class="fullWidthField">
      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      &lt;!&ndash;             BARCODE FILTER             &ndash;&gt;-->
      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      <mat-form-field fxFlex appearance="outline">-->
      <!--        <mat-label>{{'PROCESSING_VIEW.FILTER_BY_BARCODE' | translate }}</mat-label>-->
      <!--        <input matInput [(ngModel)]="barcodeToFind"-->
      <!--               name="serialFilter">-->
      <!--        <mat-icon matPrefix>search</mat-icon>-->
      <!--      </mat-form-field>-->

      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      &lt;!&ndash;             FROM-DATE FILTER           &ndash;&gt;-->
      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      <mat-form-field fxFlex appearance="outline">-->
      <!--        <mat-label>{{'PROCESSING_VIEW.FILTER_BY_FROMDATE' | translate }}</mat-label>-->
      <!--        <input matInput [matDatepicker]="bgDateS" (dateChange)="assignBGDate($event)" [value]="beginDate">-->
      <!--        <mat-datepicker-toggle matSuffix [for]="bgDateS"></mat-datepicker-toggle>-->
      <!--        <mat-datepicker #bgDateS></mat-datepicker>-->
      <!--      </mat-form-field>-->

      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      &lt;!&ndash;               TO-DATE FILTER           &ndash;&gt;-->
      <!--      &lt;!&ndash;  ************************************  &ndash;&gt;-->
      <!--      <mat-form-field fxFlex appearance="outline">-->
      <!--        <mat-label>{{'PROCESSING_VIEW.FILTER_BY_TODATE' | translate }}</mat-label>-->
      <!--        <input matInput [matDatepicker]="eDateS" (dateChange)="assignEDate($event)" [value]="endDate">-->
      <!--        <mat-datepicker-toggle matSuffix [for]="eDateS"></mat-datepicker-toggle>-->
      <!--        <mat-datepicker #eDateS></mat-datepicker>-->
      <!--      </mat-form-field>-->

      <!--      <div fxFlex="8%">-->
      <!--        <button mat-raised-button color="primary" class="findButton"-->
      <!--                (click)="getProcessingCoils(true)">{{'BUTTON.SEARCH' | translate }}</button>-->
      <!--      </div>-->
    </div>

    <div style="flex: 1 1; position:relative;">
      <div class="mat-elevation-z8 fillPage" *ngIf="!!celCategories">
        <div class="fullPageContainer" style="display: flex;flex-direction: column;background: white;">
          <div class="full-table-container" style="flex: 1 1; background: white;">
            <table mat-table [dataSource]="dataSource" aria-describedby="cels">

              <!-- ID Column -->
              <ng-container matColumnDef="id" hidden="true">
                <th mat-header-cell *matHeaderCellDef> {{'CEL_CATEGORY.TABLE.ID' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>

              <!-- CEL_NUM Column -->
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> {{'CEL_CATEGORY.TABLE.CODE' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
              </ng-container>

              <!-- CEL_DATE Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> {{'CEL_CATEGORY.TABLE.DESCRIPTION' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
              </ng-container>

              <!-- PROCURING_STATION Column -->
              <ng-container matColumnDef="addAlsoSubcontractorsAmount">
                <th mat-header-cell *matHeaderCellDef> {{'CEL_CATEGORY.TABLE.ALSO_SUBCONTRACTORS' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.addAlsoSubcontractorsAmount">check</mat-icon>
                  <mat-icon [color]="'warn'" *ngIf="!element.addAlsoSubcontractorsAmount">cancel</mat-icon>
                </td>
              </ng-container>

              <!-- Actions Column -->
<!--              <ng-container matColumnDef="actions" stickyEnd>-->
<!--                <th mat-header-cell *matHeaderCellDef> {{'CEL.TABLE.ACTIONS' | translate}} </th>-->
<!--                <td mat-cell *matCellDef="let element">-->
<!--                  <button mat-icon-button color="primary" [title]="'CEL.TABLE.ACTIONS.EDIT' | translate"-->
<!--                          [disabled]="!canEdit()"-->
<!--                          (click)="editCel(element); $event.stopPropagation();"-->
<!--                          aria-label="Visualizza bolla uscita">-->
<!--                    <mat-icon>edit</mat-icon>-->
<!--                  </button>-->

<!--                  <button mat-icon-button color="primary" [title]="'CEL.TABLE.ACTIONS.DELETE' | translate"-->
<!--                          [disabled]="!canDelete()"-->
<!--                          (click)="deleteCel(element); $event.stopPropagation();"-->
<!--                          aria-label="Visualizza bolla uscita">-->
<!--                    <mat-icon>delete</mat-icon>-->
<!--                  </button>-->
<!--                </td>-->
<!--              </ng-container>-->

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>

              <!--  ************************************  -->
              <!--               NO DATA ROW              -->
              <!--  ************************************  -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell"
                    [colSpan]="displayedColumns.length">{{'COMMON.NO_DATA_FOUND' | translate }}</td>
              </tr>
            </table>
          </div>

          <mat-divider></mat-divider>
        </div>

      </div>
    </div>

  </div>
</div>
